<template>
  <v-dialog v-model="dialog" width="400px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" outlined v-bind="attrs" v-on="on">
        Advanced Options
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <v-container>
          <v-row align="center" class="pt-4" justify="center">
            <v-col class="pt-0 pb-0">
              <v-text-field
                  v-model="fields.ballastDraft"
                  :rules="constraints.noNegatives.rules"
                  dense
                  label="Ballast draft"
                  outlined
                  suffix="m"
              ></v-text-field>
              <v-text-field
                  v-model="fields.propellerDiameter"
                  :rules="constraints.noNegatives.rules"
                  dense
                  label="Propeller diameter"
                  outlined
                  suffix="m"
              ></v-text-field>
              <v-text-field
                  v-model="fields.tropicalDraft"
                  :rules="constraints.noNegatives.rules"
                  dense
                  label="Tropical draft"
                  outlined
                  suffix="m"
              ></v-text-field>
              <v-text-field
                  v-model="fields.formFore"
                  :rules="constraints.foreAft.rules"
                  dense
                  label="Form fore"
                  outlined
              ></v-text-field>
              <v-text-field
                  v-model="fields.formAft"
                  :rules="constraints.foreAft.rules"
                  dense
                  label="Form aft"
                  outlined
              ></v-text-field>
              <v-text-field
                  v-model="fields.gearboxRatio"
                  :rules="constraints.gearboxRatio.rules"
                  dense
                  label="Gearbox ratio"
                  outlined
              ></v-text-field>
              <v-text-field
                  v-model="fields.referenceSpeed"
                  :rules="constraints.referenceSpeed.rules"
                  dense
                  label="Reference speed (knots)"
                  outlined
              ></v-text-field>
              <v-text-field
                  v-model="fields.referenceDraft"
                  :rules="constraints.referenceDraft.rules"
                  dense
                  label="Reference draft (m)"
                  outlined
              ></v-text-field>
              <v-autocomplete
                  v-model="fields.propellerType"
                  :items="['FP', 'CP']"
                  dense
                  hide-no-data
                  label="Propeller Type"
                  @change="propellerTypeUpdated"
                  outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-0">
              <v-switch
                  v-model="fields.twinSkegWithTwinShaft"
                  color="primary"
                  dense
                  label="Twin Skeg Hull with Twin Shaft"
              ></v-switch>
              <v-switch
                  v-model="fields.bulbousBow"
                  color="primary"
                  dense
                  label="Bulbous bow"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {getNumberRules} from "@/utils/inputRulesBuilder";

export default {
  data() {
    return {
      refName: "miscSpecifications",
      dialog: false,
      fields: {
        //ballastDraft: "",
        //propellerDiameter: "",
        //tropicalDraft: "",
        //formFore: "",
        //formAft: "",
        //gearboxRatio: "",
        referenceSpeed: 0,
        referenceDraft: 0,
        bulbousBow: true,
        twinSkegWithTwinShaft: false,
        propellerType: "FP",
      },
    };
  },

  methods: {
    setFields(data) {
      this.fields.ballastDraft = data.ballastDraft;
      this.fields.propellerDiameter = data.propellerDiameter;
      this.fields.propellerType = data.propellerType;
      this.fields.tropicalDraft = data.tropicalDraft;
      this.fields.formFore = data.formFore;
      this.fields.formAft = data.formAft;
      this.fields.gearboxRatio = data.gearboxRatio;
      this.fields.referenceSpeed = data.referenceSpeed;
      this.fields.referenceDraft = data.referenceDraft;
      this.fields.bulbousBow = data.bulbousBow;
      this.fields.twinSkegWithTwinShaft = data.twinSkegWithTwinShaft;
    },
    propellerTypeUpdated(newValue) {
      this.$emit("updatedPropellerType", newValue);
    }
  },

  computed: {
    constraints() {
      return {
        noNegatives: getNumberRules(this, {
          required: false,
          range: [0, -1],
        }),
        foreAft: getNumberRules(this, {
          required: false,
          range: [-3, 3],
        }),
        gearboxRatio: getNumberRules(this, {
          required: false,
          range: [1, 10],
        }),
        referenceSpeed: getNumberRules(this, {
          required: false,
          range: [10, 15],
        }),
        referenceDraft: getNumberRules(this, {
          required: false,
          range: [5, 15],
        }),
      };
    },
  },
};
</script>