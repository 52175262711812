import axios from 'axios';

export let baseURL = process.env.VUE_APP_DOMAIN;

export const AXIOS = axios.create({
    baseURL: baseURL,
    headers: {
        'Access-Control-Allow-Origin': '*'
        //'Access-Control-Allow-Origin': 'https://localhost:44360'
        //'Access-Control-Allow-Origin': 'http://localhost:7000'
    }
});