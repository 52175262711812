<template>
  <v-menu
      ref="customMenu"
      v-model="customMenu"
      :close-on-content-click="false"
      max-width="290px"
      min-width="290px"
      nudge-left="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="dateFormatted"
          :disabled="disabled"
          :label="label"
          :rules="rules"
          dense
          hint="DD/MM/YYYY format"
          outlined
          persistent-hint
          prepend-icon="mdi-calendar-blank"
          v-bind="attrs"
          @blur="date = parseDate(dateFormatted)"
          v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
        v-model="date"
        color="primary"
        no-title
        @input="customMenu = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    date: "",
    dateFormatted: "",
    customMenu: false,
  }),

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);

      this.$emit("dateUpdated", val);
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },

  props: {
    label: {
      type: String,
      default: "Date of Delivery"
    },
    rules: Array,
    disabled: Boolean,
  },
};
</script>