<template>
  <v-dialog v-model="dialog" persistent width="600px">
    <v-card>
      <v-card-title class="headline">
        Choose IHS data fields
      </v-card-title>
      <v-card-text class="pt-4">
        <v-container>
          <v-row>
            <v-col>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-center">Field</th>
                    <th class="text-left">DESMO</th>
                    <th class="text-left">IHS</th>
                    <th class="text-left">Use IHS</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in fieldNames" :key="item[0]">
                    <td class="text-left">{{ item[1] }}</td>
                    <td class="text-left">
                      {{
                        typeof desmoValues[item[0]] === 'number' ? desmoValues[item[0]].toFixed(2) : desmoValues[item[0]]
                      }}
                    </td>
                    <td class="text-left">
                      {{ typeof ihsValues[item[0]] === 'number' ? ihsValues[item[0]].toFixed(2) : ihsValues[item[0]] }}
                    </td>
                    <td>
                      <v-layout justify-right>
                        <v-switch v-model="switchValues[item[0]]" dense/>
                      </v-layout>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="completedDialog">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      desmoValues: {},
      ihsValues: {},
      switchValues: {},
    };
  },

  methods: {
    enableDialog(desmo, ihs) {
      this.dialog = true;
      this.desmoValues = desmo;
      this.ihsValues = ihs;
      for (const item of this.fieldNames) {
        this.switchValues[item[0]] = true;
      }
    },
    completedDialog() {
      this.dialog = false;
      let dialogFields = {};

      for (const val in this.switchValues) {
        if (this.switchValues[val]) {
          dialogFields[val] = typeof this.ihsValues[val] === 'number'
              ? this.ihsValues[val].toFixed(2)
              : this.ihsValues[val];
        } else {
          dialogFields[val] = typeof this.desmoValues[val] === 'number'
              ? this.desmoValues[val].toFixed(2)
              : this.desmoValues[val];
        }
      }
      this.$emit("dialogCompleted", dialogFields);
    },
  },

  props: {
    shipModel: Object,
    fieldNames: Array,
  },
};
</script>