import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)

Vue.config.productionTip = false

Vue.component('apex-chart', VueApexCharts)

new Vue({
    router,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app')