<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col class="pt-0 pl-0 pr-0 pb-0">
        <custom-snack-bar
            ref="snackbar"
            :color="snackbar.color"
            :text="snackbar.text"
        />
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submitLogin"
        >
          <v-card outlined>
            <v-img
                class="white--text align-end"
                src="@/assets/header.png"
            ></v-img>
            <v-card-text>
              <v-row align="center" justify="center">
                <v-col cols="5">
                  <v-text-field
                      v-model="fields.username"
                      :disabled="processingRequest"
                      :rules="[rules.required]"
                      label="Username"
                      required
                  ></v-text-field>

                  <v-text-field
                      v-model="fields.password"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :disabled="processingRequest"
                      :error-messages="computeErrorMessage"
                      :rules="[rules.required]"
                      :type="fieldProperties.passwordHide ? 'text' : 'password'"
                      label="Password"
                      name="input-10-1"
                      required
                      @click:append="
                      fieldProperties.passwordHide =
                        !fieldProperties.passwordHide
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  :disabled="!valid"
                  :loading="processingRequest"
                  color="secondary"
                  depressed
                  large
                  type="submit"
              >
                Log in
              </v-btn>

              <!-- <v-btn color="error" class="mr-4" @click="reset">
                Forgotten Password
              </v-btn> -->
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {authenticateUser} from "@/utils/http-methods";
import {errorDisplay} from "@/utils/messageHandler";
import {isUserLoggedIn} from "@/utils/session";
import CustomSnackBar from "@/components/CustomSnackBar";

export default {
  created() {
    if (isUserLoggedIn()) {
      console.log("Current session active");
    }
  },
  data: () => ({
    show1: false,
    valid: true,
    fields: {
      username: "",
      password: "",
    },
    fieldProperties: {
      passwordHide: false,
    },
    rules: {
      required: (value) => !!value || "Required.",
      //min: (v) => v.length >= 8 || "Min 8 characters",
      name: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
    },
    invalidCredentials: false,
    processingRequest: false,
    snackbar: {
      text: "",
      color: "error",
    },
  }),

  computed: {
    computeErrorMessage() {
      return this.invalidCredentials ? ["Wrong username and/or password!"] : [];
    },
  },
  methods: {
    submitLogin() {
      this.$refs.form.validate();
      this.invalidCredentials = false;

      this.processingRequest = true;

      authenticateUser(
          this.fields.username,
          this.fields.password,
          this.requestCompleted
      );
    },
    requestCompleted(error) {
      this.processingRequest = false;

      if (error) {
        errorDisplay(this.$refs.snackbar, this.snackbar, error);

        this.invalidCredentials = true;
        setTimeout(() => (this.invalidCredentials = false), 2000);
        return;
      }

      this.$router.push({name: "Home"});
      this.$router.go();
    },
    reset() {
      this.$refs.form.reset();
    },
  },
  components: {
    "custom-snack-bar": CustomSnackBar,
  },
};
</script>