<template>
  <div>
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="11">
        <v-file-input
            :label="name"
            :value="currentFile"
            accept=".xlsx,.xlsm"
            clearable
            counter
            dense
            full-width
            hide-details
            outlined
            show-size
            @change="selectFile"
            @click:clear="deleteItem"
        ></v-file-input>
      </v-col>

      <v-col class="pl-2 ma-0" cols="1">
        <v-btn color="primary" dark icon small @click="upload">
          <v-icon dark>mdi-cloud-upload</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" no-gutters>
      <small><a :href="templatePath" download>Download Template</a></small>
    </v-row>

    <custom-snack-bar
        ref="snackbar"
        :color="snackbar.color"
        :text="snackbar.text"
    />
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="350">
        <v-card>
          <v-card-title
          >Upload file {{ currentFile ? currentFile.name : "" }}
          </v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">
              No
            </v-btn>
            <v-btn color="primary" text @click="upload">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import CustomSnackBar from "@/components/CustomSnackBar";
import {uploadFile} from "@/utils/http-methods";

export default {
  name: "upload-files",
  /*mounted() {
    UploadService.getFiles().then((response) => {
      this.fileInfos = response.data;
    });
  },*/

  data() {
    return {
      currentFile: undefined,
      progress: 0,
      dialog: false,
      snackbar: {
        text: "",
        color: "error",
      },

      fileInfos: [],
      fileUploaded: false,
      rules: {
        fileUploadSuccess: (v) =>
            (!!v && this.fileUploaded) || this.$t("errReqUpload"),
      },
    };
  },

  methods: {
    selectFile(file) {
      this.progress = 0;
      this.currentFile = file;
      if (this.currentFile) {
        this.dialog = true;
      }
    },
    setCurrentFile(newFileName) {
      if (!newFileName) return;
      const newFile = new File([], newFileName);
      this.currentFile = newFile;
    },
    upload() {
      if (!this.currentFile) {
        this.message = "Please select a file!";
        return;
      }
      this.dialog = false;
      this.fileUploaded = false;

      uploadFile(this.currentFile, this.endpoint, (event) => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      })
          .then((response) => {
            //this.message = response.data.message;
            this.snackbar.text = "Success!";
            this.snackbar.color = "info";
            this.fileUploaded = true;

            this.$refs.snackbar.enableSnackbar();
          })
          .catch(() => {
            this.progress = 0;
            this.snackbar.text = "Could not upload the file!";
            this.snackbar.color = "error";

            this.$refs.snackbar.enableSnackbar();
            this.currentFile = undefined;
          });
    },
    deleteItem() {
      this.currentFile = undefined;
      uploadFile(null, this.endpoint, (event) => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      })
          .then((response) => {
            //this.message = response.data.message;
            this.snackbar.text = "File deleted!";
            this.snackbar.color = "info";

            this.$refs.snackbar.enableSnackbar();
          })
          .catch(() => {
            this.progress = 0;
            this.snackbar.text = "Could not delete the file!";
            this.snackbar.color = "error";

            this.$refs.snackbar.enableSnackbar();
            this.currentFile = undefined;
          });
    },
  },

  props: {
    endpoint: String,
    name: String,
    templatePath: String,
  },

  components: {
    "custom-snack-bar": CustomSnackBar,
  },
};
</script>