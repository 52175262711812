<template>
  <div id="chart">
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12">
        <apex-chart
            ref="chart"
            :options="chartOptions"
            :series="series"
            height="300"
            type="line"
        ></apex-chart>
      </v-col>
      <v-col class="pa-0" cols="2">
        <v-autocomplete
            v-model="pediSpeed"
            :items="pediSpeeds"
            dense
            item-text="pedi"
            label="PEDI Speed"
            outlined
            return-object
            single-line
            @change="selectedPEDIChanged"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {ChartOptions} from "@/utils/baseChart";
import {calculateTrendPoints} from "@/utils/utils";

export default {
  created() {
    this.generateChartOptions();
  },

  data() {
    return {
      series: [],
      chartOptions: {},

      pediSpeed: {pedi: "PEDI @ 14kn", pediKey: "PEDI_14", pediIdx: 3},
      pediSpeeds: [
        {pedi: "PEDI @ 11kn", pediKey: "PEDI_11", pediIdx: 0},
        {pedi: "PEDI @ 12kn", pediKey: "PEDI_12", pediIdx: 1},
        {pedi: "PEDI @ 13kn", pediKey: "PEDI_13", pediIdx: 2},
        {pedi: "PEDI @ 14kn", pediKey: "PEDI_14", pediIdx: 3},
        {pedi: "PEDI @ 15kn", pediKey: "PEDI_15", pediIdx: 4},
        {pedi: "PEDI @ 16kn", pediKey: "PEDI_16", pediIdx: 5},
        {pedi: "PEDI @ 17kn", pediKey: "PEDI_17", pediIdx: 6},
        {pedi: "PEDI @ 18kn", pediKey: "PEDI_18", pediIdx: 7},
        {pedi: "PEDI @ 19kn", pediKey: "PEDI_19", pediIdx: 8},
        {pedi: "PEDI @ 20kn", pediKey: "PEDI_20", pediIdx: 9},
        {pedi: "PEDI @ 21kn", pediKey: "PEDI_21", pediIdx: 10},
        {pedi: "PEDI @ 22kn", pediKey: "PEDI_22", pediIdx: 11},
      ],
      pediShipsSeries: [],
      pediShipsSeriesInvalid: [],
      pediSSSeries: [],
      pediSSSeriesInvalid: [],
    };
  },

  methods: {
    selectedPEDIChanged() {
      this.updateChart(this.shipModelPEDI);
      this.$emit("pediSpeedUpdated", this.pediSpeed.pediKey);
    },
    calculateTrendPoints(newSeries) {
      const x = [];
      const y = [];

      for (const ship in newSeries[0].data) {
        x.push(newSeries[0].data[ship].x);
        y.push(newSeries[0].data[ship].y);
      }
      for (const significantShip in newSeries[1].data) {
        x.push(newSeries[1].data[significantShip].x);
        y.push(newSeries[1].data[significantShip].y);
      }

      const points = calculateTrendPoints(x, y);

      return {
        name: "Power Trend",
        type: "line",
        data: points.power,
      };
    },

    getXAxisValue(shipModel) {
      return shipModel.scantlingDeadweight;
    },

    generateChartOptionFunc({series, seriesIndex, dataPointIndex, w}) {
      return '<div class="apexcharts-custom-tooltip">\n          '.concat(
          "<div>",
          //w.config.series[seriesIndex].name,
          "PEDI: <strong>",
          w.globals.series[seriesIndex][dataPointIndex].toFixed(4),
          "</strong> Whr/T.NM</div>",
          "<div>Vessel Name: <strong>",
          w.config.series[seriesIndex].data[dataPointIndex].vesselName,
          "</strong>\n        </div></div>"
      );
    },

    generateChartOptions() {
      const options = JSON.parse(JSON.stringify(ChartOptions));
      // These options have to match the number of series
      options.chart.zoom.zoomedArea.markers.size = [4, 4, 4, 4, 4, 0];
      options.chart.zoom.zoomedArea.markers.strokeWidth = [0, 0, 0, 0, 0, 1];
      options.markers.size = [2, 2, 2, 2, 4, 0];
      options.markers.strokeWidth = [0, 0, 0, 0, 0, 1];
      options.colors = [
        "#008ffb",
        "#00e396",
        "#555555",
        "#CBCBCB",
        "#9C27B0",
        "#FF0000",
      ];
      options.tooltip.custom = [
        this.generateChartOptionFunc,
        this.generateChartOptionFunc,
        this.generateChartOptionFunc,
        this.generateChartOptionFunc,
        this.generateChartOptionFunc,
        this.generateChartOptionFunc,
      ];
      this.chartOptions = options;
    },

    updateChart(shipModelPEDI) {
      let newSeries = [];
      const vesperModels = this.pediShipsSeries[this.pediSpeed.pediIdx];
      newSeries.push({
        name: "VESPER Models",
        type: "scatter",
        data: vesperModels ? vesperModels : [],
      });
      const significantShips = this.pediSSSeries[this.pediSpeed.pediIdx];
      newSeries.push({
        name: "Significant Ships",
        type: "scatter",
        data: significantShips ? significantShips : [],
      });

      const vesperModelsInvalid = this.pediShipsSeriesInvalid[
          this.pediSpeed.pediIdx
          ];
      newSeries.push({
        name: "VESPER Models Invalid PEDI",
        type: "scatter",
        data: vesperModelsInvalid ? vesperModelsInvalid : [],
      });
      const significantShipsInvalid = this.pediSSSeriesInvalid[
          this.pediSpeed.pediIdx
          ];
      newSeries.push({
        name: "Significant Ships Invalid PEDI",
        type: "scatter",
        data: significantShipsInvalid ? significantShipsInvalid : [],
      });

      newSeries.push({
        name: "Current Model",
        type: "scatter",
        data: [
          {
            vesselName: this.vesselName,
            x: this.xAxisValue,
            y: shipModelPEDI[this.pediSpeed.pediKey],
          },
        ],
      });

      newSeries.push(this.calculateTrendPoints(newSeries));

      this.series = newSeries;
      this.updateChartOptions();
    },
    updateChartOptions() {
      this.$refs.chart.updateOptions({
        annotations: {
          xaxis: [
            {
              x: this.vesselSubTypeData.vesselSubTypeMin,
              x2: this.vesselSubTypeData.vesselSubTypeMax,
              fillColor: "#B3F7CA",
              opacity: 0.4,
              label: {
                borderColor: "#B3F7CA",
                style: {
                  fontSize: "10px",
                  color: "#fff",
                  background: "#00E396",
                  padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                  },
                },
                offsetY: -10,
                text: this.vesselSubTypeData.vesselSubType,
              },
            },
          ],
        },
        yaxis: {
          type: "numeric",
          tickAmount: 10,
          decimalsInFloat: 2,
          title: {text: this.pediSpeed.pedi + "(Whr/T.NM)"},
          labels: {
            minWidth: "400px",
          },
        },
      });
    },
    processPEDIShips(pediShips) {
      this.pediShipsSeries = [];
      this.pediShipsSeriesInvalid = [];
      this.pediSSSeries = [];
      this.pediSSSeriesInvalid = [];

      pediShips.forEach((ps) => {
        for (let i = 0; i < ps.pediValues.length; i++) {
          if (ps.pediValues[i] == 0) return; // Ignore ships with no PEDI calculated
          if (ps.isSignificantShip) {
            ps.pediValid[i]
                ? this.addShipToPediShipSeries("pediSSSeries", i, ps)
                : this.addShipToPediShipSeries("pediSSSeriesInvalid", i, ps);
          } else {
            ps.pediValid[i]
                ? this.addShipToPediShipSeries("pediShipsSeries", i, ps)
                : this.addShipToPediShipSeries("pediShipsSeriesInvalid", i, ps);
          }
        }
      });
    },
    addShipToPediShipSeries(seriesName, shipIndex, currentShip) {
      if (!this[seriesName][shipIndex]) {
        this[seriesName][shipIndex] = [];
      }
      this[seriesName][shipIndex].push({
        vesselName: currentShip.vesselName,
        x: this.getXAxisValue(currentShip),
        y: currentShip.pediValues[shipIndex],
      });
    },
  },

  props: {
    xAxisValue: [String, Number],
    vesselName: String,
    vesselSubTypeData: Object,
    pediShips: Array,
    shipModelPEDI: Object,
  },

  watch: {
    pediShips(pediShips) {
      if (pediShips) {
        this.processPEDIShips(pediShips);
      }
    },
    shipModelPEDI(shipModelPEDI) {
      if (shipModelPEDI == null) return;

      this.updateChart(shipModelPEDI)
      this.$emit("pediSpeedUpdated", this.pediSpeed.pediKey);
    },
  },
};
</script>