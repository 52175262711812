<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card flat>
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col cols="3">
            <v-form ref="imoForm" v-model="imoValid" lazy-validation>
              <v-text-field
                  v-model="fields.imoNumber"
                  :counter="constraints.imoNumber.maxLength"
                  :disabled="isLoading"
                  :rules="constraints.imoNumber.rules"
                  dense
                  label="IMO Number"
                  outlined
                  required
              ></v-text-field>
            </v-form>
          </v-col>

          <v-col align-self="stretch" cols="4">
            <v-btn
                class="ma-1"
                color="primary"
                dense
                small
                @click.stop="loadPreviousModelPressed"
            >
              Load previous model
            </v-btn>
            <v-btn
                class="ma-1"
                color="primary"
                dense
                small
                @click.stop="loadIHSData"
            >
              Load IHS Data
            </v-btn>
          </v-col>
          <v-col align-self="stretch" cols="3">
            <v-checkbox
                v-model="ihsDataEnabled"
                :disabled="!ihsDataLoaded"
                :label="ihsDataLoadedLabel"
                class="pa-0"
                color="primary"
                dense
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-divider class="mb-2"></v-divider>
        <v-row align="center" justify="center" no-gutters>
          <v-col class="ma-1" cols="3">
            <v-autocomplete
                v-model="vesselType"
                :disabled="isLoading"
                :items="data.vesselType.values"
                :rules="[
                (v) =>
                  (v && Object.keys(v).length !== 0) || this.$t('errReqField'),
              ]"
                dense
                hide-no-data
                item-id="name"
                item-text="name"
                label="Vessel Type"
                outlined
                required
                return-object
                @change="updateVesselType"
            >
              <template v-slot:prepend>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <p class="pa-0 mb-0">
                    Ro-ro Cargo Ships are not completely supported by the Model
                    Builder, and will be approximated to a Container Vessel.
                  </p>
                </v-tooltip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col class="ma-1" cols="3">
            <v-text-field
                v-model="fields.vesselName"
                :rules="constraints.required.rules"
                dense
                label="Vessel Name"
                outlined
                required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" no-gutters>
          <v-col class="ma-1" cols="3">
            <v-text-field
                v-if="this.fields.vesselType != 'Container'"
                v-model="fields.scantlingDeadweight"
                :disabled="isLoading"
                :rules="constraints.deadweight.rules"
                dense
                label="Scantling Deadweight"
                outlined
                required
                suffix="t"
                @change="updateDeadweightAndTEU"
            ></v-text-field>
            <v-text-field
                v-else
                v-model="fields.teu"
                :disabled="isLoading"
                :rules="constraints.teu.rules"
                dense
                label="TEU"
                outlined
                required
                @change="updateDeadweightAndTEU"
            ></v-text-field>
          </v-col>
          <v-col class="ma-1" cols="3">
            <v-combobox
                v-model="fields.shipyard"
                :disabled="isLoading"
                :items="data.shipyard.values"
                :loading="data.shipyard.isLoading"
                :rules="constraints.requiredElement.rules"
                dense
                label="Shipyard"
                outlined
                required
                return-object
            />
          </v-col>
        </v-row>
        <v-row align="center" justify="center" no-gutters>
          <v-col class="ma-1" cols="3">
            <custom-date-picker
                ref="datePicker"
                :disabled="isLoading"
                :rules="this.constraints.required.rules"
                @dateUpdated="dateUpdated"
            />
          </v-col>
          <v-col align-self="stretch" class="ma-1 text-right" cols="3">
            <v-btn
                :disabled="!valid || isLoading"
                :loading="isLoading"
                color="primary"
                large
                @click.stop="generalDataCompleted"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <custom-snack-bar
          ref="snackbar"
          :color="snackbar.color"
          :text="snackbar.text"
      />
      <ship-model-select-dialog
          ref="shipModelSelectDalog"
          @shipModelSelected="shipModelSelected"
      />
    </v-card>
  </v-form>
</template>

<script>
import CustomDatePicker from "@/components/CustomDatePicker.vue";
import CustomSnackBar from "@/components/CustomSnackBar";
import ShipModelSelectDialog from "@/components/ShipModelSelectDialog";
import {getNumberRules, getRuleForElement} from "@/utils/inputRulesBuilder";
import {ENDPOINT, TYPES} from "@/utils/constants";
import {
  fetchConstant,
  fetchDefaultBuildSpecs,
  fetchDefaultContractPointSpecs,
  fetchDefaultMainEngineSpecs,
  fetchIHSShipModel,
  fetchResource,
  fetchShipModel,
} from "@/utils/http-methods";
import {ModelBuilderMixin} from "@/utils/customMixins";

export default {
  name: "GeneralData",
  mixins: [ModelBuilderMixin],

  mounted() {
    fetchResource(this.data.shipyard.endpoint, this.data.shipyard);
    fetchConstant(this.data.vesselType.endpoint, this.data.vesselType);
  },

  data: () => ({
    refName: "generalData",
    datePicker: false,
    isLoading: false,
    imoValid: true,
    ihsDataLoaded: false,
    previousModelLoaded: false,
    ihsImoNumber: "",
    ihsDataEnabled: false,
    fields: {
      vesselType: "",
      vesselTypeName: "",
      imoNumber: "",
      buildDate: "",
      shipyard: "",
      //scantlingDeadweight: "",
      //teu: 0,
    },
    data: {
      shipyard: {
        fieldName: "yards",
        endpoint: ENDPOINT.YARD,
        isLoading: false,
        values: [],
      },
      vesselType: {
        endpoint: ENDPOINT.VESSEL_TYPES,
        isLoading: false,
        values: [
          /*{ name: "Bulk Carrier", id: "Bulk" },
          { name: "Gas Carrier", id: "Tanker" },
          { name: "Tanker", id: "Tanker" },
          { name: "Container Ship", id: "Container" },
          { name: "LNG Carrier", id: "Tanker" },
          { name: "Ro-ro Cargo Ship", id: "Container" },*/
        ],
      },
    },

    snackbar: {
      color: "error",
      text: "",
    },
    vesselType: {},
  }),

  methods: {
    loadPreviousModelPressed() {
      this.loadPreviousModel();
    },
    loadPreviousModel() {
      if (!this.$refs.imoForm.validate()) {
        return;
      }

      this.isLoading = true;

      fetchShipModel(this.fields.imoNumber)
          .then((data) => {
            if (data.shipModels.length > 0) {
              // Admin case where there is multiple models found
              if (data.shipModels.length > 1) {
                this.$refs.shipModelSelectDalog.enableDialog(data.shipModels);
              } else {
                this.processShipModelData(data.shipModels[0], false);
              }
            } else {
              this.snackbar.text =
                  "Could not find vessel data for " + this.fields.imoNumber;
              this.snackbar.color = "warn";
              this.$refs.snackbar.enableSnackbar();
            }

            this.previousModelLoaded = true;
            this.isLoading = false;
          })
          .catch((error) => {
            if (error.response && error.response.status == 401) {
              this.snackbar.text =
                  "IMO Number " +
                  this.fields.imoNumber +
                  " is assigned to another client!";
              this.snackbar.color = "warn";
              this.$refs.snackbar.enableSnackbar();
            } else {
              console.log(error);
            }
            this.isLoading = false;
          });
    },
    processShipModelData(shipModel, isShallow = false) {
      if (!isShallow) {
        this.mapData(shipModel.shipModelData);
        this.snackbar.text = "Loaded vessel data for " + this.fields.imoNumber;
        this.snackbar.color = "info";
        this.$refs.snackbar.enableSnackbar();
      }
      this.$emit("previousShipModelDataRetrieved", shipModel, !isShallow);
    },
    shipModelSelected(selectedShipModel) {
      selectedShipModel.shipModelData.clientName = selectedShipModel.clientName;
      selectedShipModel.shipModelData.clientId = selectedShipModel.clientId;
      this.processShipModelData(selectedShipModel, false);
    },
    loadIHSData() {
      if (!this.$refs.imoForm.validate()) {
        return;
      }

      this.isLoading = true;
      this.ihsDataLoaded = false;

      fetchIHSShipModel(this.fields.imoNumber)
          .then((data) => {
            this.$emit("ihsDataReceived", data);
            data.vesselName = this.titleCase(data.vesselName);
            this.mapData(data);
            this.ihsDataLoaded = true;
            this.ihsDataEnabled = true;
            this.ihsImoNumber = this.fields.imoNumber;

            this.snackbar.text = "Loaded IHS data for " + this.ihsImoNumber;
            this.snackbar.color = "info";
            this.$refs.snackbar.enableSnackbar();
          })
          .catch((error) => {
            console.log(error);
            this.snackbar.text =
                "Error retrieving IHS data for " + this.ihsImoNumber;
            this.snackbar.color = "error";
            this.$refs.snackbar.enableSnackbar();
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    mapData(data) {
      this.fields.buildDate = data.date ? data.date : data.buildDate; // Change build date param
      this.$refs.datePicker.date = this.fields.buildDate.split("T")[0];
      this.fields.shipyard = data.shipyard;
      this.fields.scantlingDeadweight = data.scantlingDeadweight;

      this.fields.teu = data.teu;
      this.fields.vesselName = data.vesselName;
      this.fields.vesselType = data.vesselType
          ? data.vesselType
          : this.fields.vesselType;
      this.fields.vesselTypeName = data.vesselTypeName;
      this.vesselType = this.data.vesselType.values.find(
          (vt) => vt.name == data.vesselTypeName
      );
    },

    dateUpdated(newDate) {
      this.fields.buildDate = newDate;
    },

    generalDataCompleted() {
      if (!this.$refs.form.validate() || !this.$refs.imoForm.validate()) {
        return;
      }

      this.updateVesselType();

      if (this.ihsDataEnabled) {
        fetchIHSShipModel(this.fields.imoNumber, this.fields)
            .then((data) => {
              this.$emit("ihsDataReceived", data);
              this.shallowLoadPreviousModel();
            })
            .catch(console.log);
      } else if (this.previousModelLoaded) {
        this.$emit("generalDataCompleted", this.fields, this.ihsDataEnabled);
        this.getDefaultValues();
      } else {
        this.shallowLoadPreviousModel();
      }
    },

    shallowLoadPreviousModel() {
      if (!this.previousModelLoaded) {
        fetchShipModel(this.fields.imoNumber)
            .then((data) => {
              if (data.shipModels.length > 0) {
                this.processShipModelData(data.shipModels[0], true);
                this.previousModelLoaded = true;
              }
              this.$emit(
                  "generalDataCompleted",
                  this.fields,
                  this.ihsDataEnabled
              );
              this.getDefaultValues();
            })
            .catch((err) => {
            });
      }
    },

    getDefaultValues() {
      this.isLoading = true;

      fetchDefaultBuildSpecs(this.fields)
          .then((buildData) => {
            this.isLoading = false;
            this.$emit("defaultBuildSpecsRetrieved", buildData);

            fetchDefaultMainEngineSpecs(this.fields)
                .then((meData) => {
                  this.$emit("defaultMainEngineSpecsRetrieved", meData);

                  fetchDefaultContractPointSpecs(this.fields)
                      .then((meData) => {
                        this.$emit("defaultContractPointSpecsRetrieved", meData);
                        this.$emit("generalInformationCompleted", meData);
                      })
                      .catch((error) => {
                        console.log(error);
                      })
                      .finally();
                })
                .catch((error) => {
                  console.log(error);
                });
          })
          .catch((error) => {
            console.log(error);
          });
    },

    updateDeadweightAndTEU(/*newValue*/) {
      if (this.fields.vesselType != "Container") {
        delete this.fields["teu"];
      } else {
        delete this.fields["scantlingDeadweight"];
      }
    },

    updateVesselType() {
      this.fields.vesselType = this.vesselType ? this.vesselType.id : "";
      this.fields.vesselTypeName = this.vesselType ? this.vesselType.name : "";
    },
    titleCase(str) {
      str = str.toLowerCase();

      str = str.split(" ");

      for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      }

      return str.join(" ");
    },
  },

  computed: {
    constraints() {
      return {
        requiredElement: getRuleForElement(this, TYPES.ELEMENT, false),
        imoNumber: getRuleForElement(
            this,
            TYPES.NUMBER,
            false,
            7,
            (v) => (v && v.toString().length >= 7) || this.$t("errMinLength")
        ),
        deadweight: getNumberRules(this, {
          required: true,
          range: [0, 1000000],
        }),
        teu: getRuleForElement(this, TYPES.NUMBER, false),
        required: getRuleForElement(this, TYPES.TEXT, false),
      };
    },
    ihsDataLoadedLabel() {
      return this.ihsImoNumber
          ? `Use IHS Data (${this.ihsImoNumber})`
          : "Use IHS Data";
    },
  },

  components: {
    "custom-date-picker": CustomDatePicker,
    "custom-snack-bar": CustomSnackBar,
    "ship-model-select-dialog": ShipModelSelectDialog,
  },
};
</script>