import {AXIOS} from "./axios";
import {ENDPOINT, SESSION_INFO} from "./constants";
import {getUserToken} from "@/utils/session";

function generateHeader(extraHeaders = {}) {
    return {
        headers: {
            Authorization: "Bearer " + getUserToken(),
            ...extraHeaders,
        },
    };
}

function post(endpoint, data, extraHeaders = {}) {
    return new Promise((resolve, reject) => {
        AXIOS.post(endpoint, data, generateHeader(extraHeaders))
            .then((result) => {
                resolve(result.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

function get(endpoint) {
    return new Promise((resolve, reject) => {
        AXIOS.get(endpoint, generateHeader())
            .then((result) => {
                resolve(result.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

function generateBlobResponseRequest(url, data) {
    return new Promise((resolve, reject) => {
        AXIOS({
            url: url,
            method: "POST",
            responseType: "blob",
            ...generateHeader(),
            data: data,
        })
            .then((result) => {
                resolve(result.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function authenticateUser(username, password, callback) {
    AXIOS.post(ENDPOINT.LOGIN, {username, password})
        .then((response) => {
            if (response.status == 200) {
                localStorage.setItem(
                    SESSION_INFO.SESSION_INFO,
                    JSON.stringify({
                        token: response.data.tokenString,
                        expiryDate: Date.parse(response.data.expiryDate),
                        username: response.data.username,
                    })
                );
                console.log("Stored session!");
                callback();
            }
        })
        .catch(callback);
}

export function fetchResource(endpoint, object = {}) {
    return new Promise((resolve, reject) => {
        if (object.isLoading) return reject();
        object.isLoading = true;

        AXIOS.get(endpoint, generateHeader())
            .then((response) => {
                if (response.status == 200) {
                    object.isLoading = false;
                    object.values = response.data[object.fieldName];
                    object.values.sort();
                    return resolve(response.data);
                }
                reject(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function fetchConstant(endpoint, object = {}) {
    return new Promise((resolve, reject) => {
        if (object.isLoading) return reject();
        object.isLoading = true;

        AXIOS.get(endpoint, generateHeader())
            .then((response) => {
                if (response.status == 200) {
                    object.isLoading = false;
                    /*object.values = Object.entries(response.data).map(([k, v]) => {
                      return { name: k, id: v };
                    });*/
                    object.values = [];
                    response.data.forEach(entry => {
                        object.values.push({"name": entry.key, "id": entry.value});
                    });
                    //object.values = response.data.forEach(entry => {name: entry})
                    object.values.sort();
                    return resolve(response.data);
                }
                reject(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function fetchDefaultBuildSpecs(data) {
    return post(ENDPOINT.BUILD_DEFAULTS, data);
}

export function fetchDefaultMainEngineSpecs(data) {
    return post(ENDPOINT.MAIN_ENGINE_DEFAULTS, data);
}

export function fetchDefaultContractPointSpecs(data) {
    return post(ENDPOINT.CONTRACT_POINT_DEFAULTS, data);
}

export function fetchSimilarMCRShips(vesselType, mcr) {
    return get(ENDPOINT.SIMILAR_MAIN_ENGINE + "/" + vesselType + "/" + mcr);
}

export function calculateShipModelPEDI(data) {
    return post(ENDPOINT.PEDI_CREATE_ENDPOINT, data);
}

export function fetchVESPERShipModels(data) {
    return post(ENDPOINT.VESPER_MODELS_ENDPOINT, data);
}

export function fetchIHSShipModel(imoNumber, data = {}) {
    if (Object.entries(data).length !== 0) {
        return post(ENDPOINT.IHS_SHIP_MODEL_ENDPOINT, {imoNumber, ...data});
    } else {
        return get(ENDPOINT.IHS_SHIP_MODEL_ENDPOINT + "/" + imoNumber);
    }
}

export function fetchShipModel(imoNumber) {
    return get(ENDPOINT.SHIP_MODEL + "/" + imoNumber);
}

export function fetchValidationPoints(data) {
    return post(ENDPOINT.VALIDATION_POINTS, data);
}

export function addSisterVessel(sisterImoNumber, data) {
    return generateBlobResponseRequest(
        ENDPOINT.ADD_SISTER_ENDPOINT.replace("{imoNumber}", sisterImoNumber),
        data
    );
}

export function generateShipModel(data) {
    return generateBlobResponseRequest(ENDPOINT.SHIP_MODEL, data);
}

export function uploadFile(file, endpoint, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);

    return AXIOS.post(endpoint, formData, {
        headers: {
            Authorization: "Bearer " + getUserToken(),
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
    });
}