<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card flat>
      <v-card-text>
        <v-row justify="center">
          <v-col>
            <v-row justify="center">
              <v-col class="text-center" cols="10">
                <p>PEDI at current speed: {{ currentPediValue }}</p>
                <v-text-field
                    v-model="fields.contractPointSpeed"
                    :class="[getColorForValue('contractPointSpeed')]"
                    :rules="constraints.noNegativesReq.rules"
                    dense
                    label="Contract Speed"
                    outlined
                    suffix="kn"
                ></v-text-field>
                <v-text-field
                    v-model="fields.contractPointDraft"
                    :class="[getColorForValue('contractPointDraft')]"
                    :rules="constraints.noNegativesReq.rules"
                    dense
                    label="Contract Point Draft"
                    outlined
                    suffix="m"
                ></v-text-field>
                <v-text-field
                    v-model="fields.serviceMargin"
                    :class="[getColorForValue('serviceMargin')]"
                    :rules="constraints.percent.rules"
                    dense
                    label="Service Margin"
                    outlined
                    suffix="%"
                ></v-text-field>
                <v-text-field
                    v-model="contractPointPower"
                    dense
                    disabled
                    label="Contract Point Power (NCR inc. SM)"
                    outlined
                    suffix="kW"
                ></v-text-field>
                <v-btn
                    :disabled="!valid"
                    :loading="isLoading"
                    color="primary"
                    @click.stop="updateShipModelPEDI"
                >Update PEDI
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <upload-files
                    ref="fileUpload"
                    :endpoint="uploadFilesEndpoint"
                    name="Hydrostatic & Sea Trial Excel"
                    templatePath="files/HSData_Template.xlsm"
                ></upload-files>
              </v-col>
            </v-row>
          </v-col>

          <v-col align-self="center" class="ml-auto text-center" cols="9">
            <pedi-chart
                :pediShips="VESPERModels"
                :shipModelPEDI="shipModelPEDI"
                :vesselName="vesselName"
                :vesselSubTypeData="vesselSubTypeData"
                :xAxisValue="xAxisValue"
                @pediSpeedUpdated="pediSpeedUpdated"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <ihs-data-dialog
          ref="ihsDialog"
          :fieldNames="ihsFieldNames"
          :shipModel="shipModel"
          @dialogCompleted="dialogCompleted"
      />
    </v-card>
  </v-form>
</template>

<script>
import PEDIChart from "@/components/PEDIChart";
import UploadFiles from "@/components/UploadFiles";
import IHSDataDialog from "@/components/IHSDataDialog";
import {ModelBuilderMixin} from "@/utils/customMixins";
import {getNumberRules} from "@/utils/inputRulesBuilder";
import {ENDPOINT} from "@/utils/constants";
import {calculateShipModelPEDI} from "@/utils/http-methods";

export default {
  name: "ContractPointSpecifications",
  mixins: [ModelBuilderMixin],

  data: () => ({
    refName: "contractPointSpecs",
    isLoading: false,
    fields: {
      contractPointSpeed: "",
      contractPointDraft: "",
      serviceMargin: "",
    },
    shipModelPEDI: {},
    currentPediValue: "",

    prevContractPointSpeed: -1,
    updatingPEDI: false,

    ihsFieldNames: [["contractPointSpeed", "Contract Point Speed"]],
  }),

  computed: {
    uploadFilesEndpoint() {
      if (this.shipModelLoaded) {
        return ENDPOINT.HYDROSTATIC_EXCEL_ENDPOINT.replace(
            "{imoNumber}",
            this.shipModel.imoNumber
        );
      }
      return "";
    },
    xAxisValue() {
      if (!this.shipModel) return 0;
      return this.shipModel.scantlingDeadweight;
    },
    vesselName() {
      return this.shipModel ? this.shipModel.vesselName : "";
    },
    vesselSubTypeData() {
      return this.shipModel
          ? {
            vesselSubType: this.shipModel.vesselSubType,
            vesselSubTypeMin: this.shipModel.vesselSubTypeMin,
            vesselSubTypeMax: this.shipModel.vesselSubTypeMax,
          }
          : {};
    },
    constraints() {
      return {
        percent: getNumberRules(this, {required: true, range: [0, 100]}),
        noNegativesReq: getNumberRules(this, {
          required: true,
          range: [0, -1],
        }),
      };
    },
    contractPointPower() {
      if (this.shipModel && this.shipModel.contractPointPower) {
        return this.shipModel.contractPointPower.toFixed(2);
      }
      return 0;
    },
  },

  methods: {
    updateShipModelPEDI() {
      this.isLoading = true;
      const mergedShipModel = Object.assign({}, this.shipModel, this.fields);

      calculateShipModelPEDI(mergedShipModel)
          .then((data) => {
            this.shipModelPEDI = data;
            this.updatingFields = true; // To avoid an endless loop updating the shipmodel
            this.$emit("updateShipModelFields");
            this.isLoading = false;
          })
          .catch(console.log);
    },
    enableIHSDataPicker(shipModel) {
      // console.log("Enabling IHS dialog", shipModel)
      this.$refs.ihsDialog.enableDialog(shipModel, shipModel.ihsShipModel);
    },
    dialogCompleted(dialogFields) {
      for (const field in dialogFields) {
        this.fields[field] = dialogFields[field];
      }
    },
    setFields(data) {
      this.fields.contractPointSpeed = data.contractPointSpeed;
      this.fields.contractPointDraft = data.contractPointDraft;
      this.fields.serviceMargin = data.serviceMargin;
    },
    setUploadFile(file) {
      this.$refs.fileUpload.setCurrentFile(file);
    },

    pediSpeedUpdated(pediKey) {
      if (this.shipModelPEDI)
        this.currentPediValue = this.shipModelPEDI[pediKey].toFixed(4) + " Whr/T.NM";
    }
  },

  props: {
    shipModel: Object,
    VESPERModels: Array,
  },

  watch: {
    shipModel(newShipModel) {
      if (newShipModel.contractPointSpeed != null) {
        if (!this.shipModelLoaded) {
          this.shipModelLoaded = true;
          this.prevContractPointSpeed = newShipModel.contractPointSpeed;

          this.fields.contractPointSpeed = newShipModel.contractPointSpeed.toFixed(
              2
          );
          this.fields.contractPointDraft = newShipModel.contractPointDraft.toFixed(
              2
          );
          this.fields.serviceMargin = newShipModel.serviceMargin.toFixed(2);
        }

        if (this.valid) {
          if (this.updatingFields) {
            this.updatingFields = false;
          } else {
            this.updateShipModelPEDI();
          }
        }
      }
    },
  },

  components: {
    "pedi-chart": PEDIChart,
    "upload-files": UploadFiles,
    "ihs-data-dialog": IHSDataDialog,
  },
};
</script>

<style scoped>
.non-default-field >>> .v-text-field__slot input {
  color: green;
}

.non-default-ihs-field >>> .v-text-field__slot input {
  color: blue;
}
</style>