export const ModelBuilderMixin = {
    data: () => ({
        refName: "mixin",
        valid: true,
        lastValid: true,
        shipModelLoaded: false,
    }),

    methods: {
        getColorForValue(value) {
            if (this.shipModelLoaded) {
                const fieldVal =
                    typeof this.fields[value] == "number"
                        ? this.fields[value].toFixed(2)
                        : this.fields[value];
                const modelVal =
                    typeof this.shipModel[value] == "number"
                        ? this.shipModel[value].toFixed(2)
                        : this.fields[value];

                if (fieldVal == modelVal) {
                    return "non-default-field";
                }

                if (this.shipModel.ihsData) {
                    const ihsVal =
                        typeof this.shipModel.ihsData[value] == "number"
                            ? this.shipModel.ihsData[value].toFixed(2)
                            : this.fields.ihsData[value];
                    if (fieldVal == ihsVal) {
                        return "non-default-ihs-field";
                    }
                }
            }
            return "";
        },
    },

    watch: {
        valid(newValue) {
            if (!this.shipModelLoaded) return;

            if (newValue == false) {
                this.$emit("validStep", this.refName, newValue);
                this.lastValid = newValue;
            } else if (!this.lastValid) {
                this.$emit("validStep", this.refName, newValue);
                this.lastValid = newValue;
            }
        },
    },
};