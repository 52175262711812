<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-row justify="center">
      <v-col cols="6">
        <v-container fluid>
          <v-row>
            <v-col>
              <misc-specifications-consumptions ref="consumptionsDialog"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-0" cols="6">
              <v-switch
                  v-model="fields.torsionMeter"
                  color="primary"
                  dense
                  label="Torsion Meter Fitted"
              ></v-switch>
            </v-col>
            <v-col class="pt-0 pb-0" cols="6">
              <v-autocomplete
                  v-model="fields.cylinderOilModel"
                  :items="tables.cylinderOilModel.values"
                  clearable
                  dense
                  hide-no-data
                  label="Cylinder Oil Model"
                  outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-0" cols="6">
              <v-switch
                  v-model="fields.auxEngineKWhCounter"
                  color="primary"
                  dense
                  label="Aux Engine kWh Counter"
              ></v-switch>
            </v-col>
            <v-col class="pt-0 pb-0" cols="6">
              <v-text-field
                  v-model="fields.userVesselClass"
                  dense
                  label="User Vessel Class"
                  outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-0" cols="6">
              <v-switch
                  v-model="fields.scrubberFitted"
                  color="primary"
                  dense
                  label="Scrubber Fitted"
              ></v-switch>
            </v-col>
            <v-col class="pt-0 pb-0" cols="6">
              <v-autocomplete
                  v-model="fields.windModel"
                  :items="tables.windModel.values"
                  dense
                  hide-no-data
                  item-text="text"
                  item-value="value"
                  label="Wind Model"
                  outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row justify="space-around">
            <v-col class="pt-0 pb-0" cols="6">
              <v-switch
                  v-model="fields.rpmModel"
                  :disabled="fields.lvt || propellerTypeCP"
                  color="primary"
                  dense
                  label="RPM Model"
              ></v-switch>
            </v-col>
            <v-col class="pt-0 pb-0" cols="6">
              <misc-specifications-cii
                  ref="ciiDialog"
                  :vesselTypeName="shipModel.vesselTypeName"
              />
            </v-col>
          </v-row>
          <v-row v-if="fields.rpmModel">
            <small class="pb-2">Light Running Margin</small>
          </v-row>
          <v-row v-if="fields.rpmModel">
            <v-col class="pt-0 pb-0" cols="3">
              <v-text-field
                  v-model="lrm.LRM1"
                  :rules="constraints.lrm.rules"
                  dense
                  label="LRM Ballast"
                  outlined
                  suffix="%"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0 pb-0" cols="3">
              <v-text-field
                  v-model="lrm.LRM2"
                  :rules="constraints.lrm.rules"
                  dense
                  label="LRM Design"
                  outlined
                  suffix="%"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0 pb-0" cols="3">
              <v-text-field
                  v-model="lrm.LRM3"
                  :rules="constraints.lrm.rules"
                  dense
                  label="LRM Scant."
                  outlined
                  suffix="%"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-0" cols="6">
              <v-switch
                  v-model="fields.lvt"
                  :disabled="propellerTypeCP"
                  color="primary"
                  dense
                  label="Evaluate RPM slopes"
                  @change="lvtChanged"
              ></v-switch>
            </v-col>
            <v-col class="pt-0 pb-0 text-right" cols="6">
              <misc-specifications-advanced ref="advancedDialog" @updatedPropellerType="updatedPropellerType"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                v-if="shipModel.vesselType == 'Tanker'"
                class="pt-0 pb-0"
                cols="6"
            >
              <v-autocomplete
                  v-model="fields.inertGasSystem"
                  :items="['Inert Gas System', 'Flue Gas System']"
                  clearable
                  dense
                  hide-no-data
                  label="Inert Gas"
                  outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col class="vl" cols="6">
        <h3 class="mb-4">Auxiliary Engines</h3>
        <custom-data-table
            ref="dataTable"
            :fields="tables.auxEngine.fields"
            :range="[1, 5]"
            :tableKey="tables.auxEngine.tableKey"
        >
          <template v-slot:tableHeader>
            <th class="text-left">Aux No.</th>
            <th class="text-left">Manufacturer</th>
            <th class="text-left">Model</th>
            <th class="text-left">Generator Output (kW)</th>
          </template>
          <template v-slot:tableBody="slotProps">
            <td class="text-right">{{ slotProps.item.engineNumber }}</td>
            <td>
              <v-autocomplete
                  v-model="slotProps.item.engineManufacturerId"
                  :items="auxEngineManufacturer.values"
                  :loading="auxEngineManufacturer.isLoading"
                  clearable
                  dense
                  hide-details
                  item-text="name"
                  item-value="id"
                  label="Manufacturer"
                  outlined
                  single-line
                  @change="slotProps.item.engineModel = ''"
              />
            </td>
            <td>
              <v-text-field
                  v-model="slotProps.item.engineModel"
                  dense
                  hide-details
                  label="Model"
                  outlined
                  single-line
              />
            </td>
            <td>
              <v-text-field
                  v-model="slotProps.item.maxGenOutput"
                  :rules="constraints.noNegativesReq.rules"
                  dense
                  hide-details
                  single-line
              ></v-text-field>
            </td>
          </template>
        </custom-data-table>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import CustomDataTable from "@/components/CustomDataTable";
import MiscSpecificationsConsumptions from "@/components/MiscSpecificationsConsumptions";
import MiscSpecificationsAdvanced from "@/components/MiscSpecificationsAdvanced";
import MiscSpecificationsCII from "@/components/MiscSpecificationsCII";
import {getNumberRules} from "@/utils/inputRulesBuilder";
import {ModelBuilderMixin} from "@/utils/customMixins";

export default {
  name: "MiscSpecifications",
  mixins: [ModelBuilderMixin],

  data() {
    return {
      refName: "miscSpecifications",
      dialog: false,

      tables: {
        auxEngine: {
          tableKey: "engineNumber",
          fields: {
            engineNumber: 0,
            engineManufacturerId: "",
            engineModel: "",
            maxGenOutput: 0,
          },
        },
        cylinderOilModel: {
          values: ["Sulphur Dependent", "RPM Dependent", "Power Dependent"],
        },
        windModel: {
          values: [
            {text: "Default", value: 0},
            {text: "Bulk / Tankers", value: 1},
            {text: "General Cargo Vessel", value: 2},
            {text: "Container", value: 3},
            {text: "LNG Carrier", value: 4},
            {text: "LNG Carrier - Prismatic Integrated", value: 5},
            {text: "LNG Carrier - Prismatic Extended", value: 6},
            {text: "Ro-ro Cargo Ship (VC)", value: 7},
          ],
        },
      },
      fields: {
        auxEngines: [],
        torsionMeter: true,
        auxEngineKWhCounter: true,
        scrubberFitted: false,
        lvt: true,
        rpmModel: true,
        cylinderOilModel: "Sulphur Dependent",
        inertGasSystem: "Inert Gas System",
        windModel: 0,
      },
      lrm: {
        LRM1: 0,
        LRM2: 0,
        LRM3: 0,
      },
      propellerTypeCP: false,
      shipModelLoaded: false,
    };
  },

  methods: {
    getFields() {
      let fields = {...this.fields};

      fields = Object.assign({}, fields, this.$refs.consumptionsDialog.fields);
      fields = Object.assign({}, fields, this.$refs.advancedDialog.fields);
      fields.cii = this.$refs.ciiDialog.fields;
      fields.auxEngines = this.$refs.dataTable.rows;

      if (this.fields.rpmModel) {
        fields = Object.assign({}, fields, this.lrm);
      }

      return fields;
    },
    setFields(data) {
      this.fields.auxEngines = data.auxEngines;
      this.fields.torsionMeter = data.torsionMeter;
      this.fields.auxEngineKWhCounter = data.auxEngineKWhCounter;
      this.fields.scrubberFitted = data.scrubberFitted;
      this.fields.lvt = data.lvt;
      this.fields.rpmModel = data.rpmModel;
      this.fields.cylinderOilModel = data.cylinderOilModel;
      this.fields.inertGasSystem = data.inertGasSystem;
      this.fields.windModel = data.windModel;

      this.$refs.dataTable.setRows(data.auxEngines);
      this.$refs.consumptionsDialog.setFields(data);
      this.$refs.advancedDialog.setFields(data);
      this.$refs.ciiDialog.setFields(data);
      this.lrm.LRM1 = data.LRM1;
      this.lrm.LRM2 = data.LRM2;
      this.lrm.LRM3 = data.LRM3;
    },
    addAuxEngineRow(row) {
      this.$refs.dataTable.addEntry(row);
    },
    lvtChanged(enabled) {
      if (enabled) {
        this.fields.rpmModel = true;
      }
    },
    updatedPropellerType(propellerType) {
      if (propellerType === "CP") {
        this.fields.rpmModel = false;
        this.fields.lvt = false;
        this.propellerTypeCP = true;
      } else {
        this.propellerTypeCP = false;
      }
    }
  },

  computed: {
    constraints() {
      return {
        noNegativesReq: getNumberRules(this, {
          required: true,
          range: [0, -1],
        }),
        noNegatives: getNumberRules(this, {
          required: false,
          range: [0, -1],
        }),
        lrm: getNumberRules(this, {
          required: false,
          range: [0, 10],
        }),
      };
    },
  },

  props: {
    shipModel: Object,
    auxEngineManufacturer: Object,
  },

  components: {
    "custom-data-table": CustomDataTable,
    "misc-specifications-consumptions": MiscSpecificationsConsumptions,
    "misc-specifications-advanced": MiscSpecificationsAdvanced,
    "misc-specifications-cii": MiscSpecificationsCII,
  },

  watch: {
    shipModel(newShipModel) {
      if (newShipModel.LRM1 != null && !this.shipModelLoaded) {
        this.shipModelLoaded = true;
        this.lrm.LRM1 = this.shipModel.LRM1.toFixed(2);
        this.lrm.LRM2 = this.shipModel.LRM2.toFixed(2);
        this.lrm.LRM3 = this.shipModel.LRM3.toFixed(2);
      }
    },
  },
};
</script>

<style>
/*.vl {
  border-left: 2px solid #1976d2;
}*/
</style>