import Vue from 'vue';
import VueRouter from 'vue-router';
import LoginView from '@/views/LoginView.vue';
import Home from '@/views/Home.vue';

import {isUserLoggedIn} from '@/utils/session';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginView,
        beforeEnter(to, from, next) {
            // called before the route that renders this component is confirmed.
            // does NOT have access to `this` component instance,
            // because it has not been created yet when this guard is called!

            // Redirect to home
            if (isUserLoggedIn()) {
                next({name: 'Home'});
            } else {
                next();
            }
        },
    },
];

const router = new VueRouter({
    routes,
});

router.beforeEach((to, from, next) => {
    // Do not redirect if we are already trying to log in
    if (to.name === 'Login') {
        next();
        return;
    }

    // Redirect to login if user is not logged in
    if (isUserLoggedIn()) {
        next();
    } else {
        next({name: 'Login'});
    }
});

export default router;