<template>
  <v-card flat>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col class="pa-0 text-left" cols="6">
            <h3 class="pb-2">Ship Model Summary</h3>
            <p><b>Vessel Type:</b> {{ shipModel.vesselTypeName }}</p>
            <p><b>IMO Number:</b> {{ shipModel.imoNumber }}</p>
            <p><b>Vessel Name:</b> {{ shipModel.vesselName }}</p>
            <p v-if="shipModel.vesselType == 'Container'">
              <b>TEU:</b> {{ shipModel.teu }}
            </p>
            <p v-else>
              <b>Scantling Deadweight:</b> {{ shipModel.scantlingDeadweight }}
            </p>
            <p><b>Date of delivery:</b> {{ shipModel.buildDate }}</p>
          </v-col>

          <v-col align-self="center" class="pa-0 text-center" cols="6">
            <v-row justify="center">
              <v-col align-self="center" class="pa-0 text-center" cols="8">
                <v-text-field
                    v-model="fields.userInitials"
                    dense
                    label="User Initials"
                    outlined
                />
                <v-text-field
                    v-model="filename"
                    dense
                    label="Filename"
                    outlined
                />
                <v-textarea
                    v-model="fields.comments"
                    height="100"
                    label="Additional Comments"
                    no-resize
                    outlined
                ></v-textarea>
              </v-col>

              <v-col align-self="center" class="pa-0 text-center" cols="12">
                <v-btn
                    :loading="isLoading"
                    color="primary"
                    x-large
                    @click.stop="triggerValidation"
                >
                  Create Model
                </v-btn>
              </v-col>
            </v-row>
            <custom-snack-bar
                ref="snackbar"
                :color="snackbar.color"
                :text="snackbar.text"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import CustomSnackBar from "@/components/CustomSnackBar";
import {generateShipModel} from "@/utils/http-methods";
import {ModelBuilderMixin} from "@/utils/customMixins";
import {getUsername} from "@/utils/session";

export default {
  name: "GenerateShipModel",
  mixins: [ModelBuilderMixin],

  data() {
    return {
      isLoading: false,

      fields: {
        userInitials: "",
        comments: "",
      },
      filename: "",

      snackbar: {
        color: "error",
        text: "",
      },
    };
  },

  methods: {
    generateFilename() {
      if (!this.filename) this.filename = this.getFileName();
      console.log("Filename", this.filename);
    },
    setFields(data) {
      this.fields.comments = data.comments;
      this.fields.userInitials = data.userInitials;
    },
    triggerValidation() {
      this.isLoading = true;
      this.$emit("validModel");
    },
    validationResult(isValid) {
      if (isValid) {
        this.generateShipModel();
      } else {
        this.isLoading = false;
      }
    },
    getFileName() {
      let usernameWithoutTeam = getUsername().replace("Team", "");

      if (this.shipModel.userVesselClass) {
        return (
            usernameWithoutTeam +
            " " +
            this.shipModel.userVesselClass +
            " Ship Model.xlsm"
        );
      }

      let finalString =
          usernameWithoutTeam + " " + this.shipModel.shipyard.split(" ")[0];
      if (this.shipModel.teu) {
        finalString +=
            " " + Math.round(this.shipModel.teu / 100) * 100 + " TEU ";
      } else {
        finalString +=
            " " +
            Math.round(this.shipModel.scantlingDeadweight / 1000) +
            "k DWT ";
      }

      finalString += "Ship Model.xlsm";

      return finalString;
    },

    generateShipModel() {
      const shipModel = Object.assign({}, this.shipModel, {...this.fields});
      generateShipModel(shipModel)
          .then((data) => {
            this.snackbar.text = "Success";
            this.snackbar.color = "info";
            this.$refs.snackbar.enableSnackbar();

            this.isLoading = false;
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", this.filename);
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            console.log(err);
            this.snackbar.text = "There was an error creating the vessel";
            this.snackbar.color = "error";
            this.$refs.snackbar.enableSnackbar();
            this.isLoading = false;
          });
    },
  },

  props: {
    shipModel: Object,
  },
  components: {
    "custom-snack-bar": CustomSnackBar,
  },
};
</script>