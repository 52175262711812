export const SESSION_INFO = {
    SESSION_INFO: "SESSION_INFO",
    ACTIVE: "active",
    INACTIVE: "inactive",
    TOKEN: "token",
    EXPIRY_DATE: "expiryDate",
    USERNAME: "username",
};

export const TYPES = {
    STRING: 0,
    NUMBER: 1,
    ELEMENT: 2,
    EMAIL: 3,
};

export const ENDPOINT = {
    LOGIN: "api/auth/token",
    YARD: "api/yard",
    VESSEL_TYPES: "api/constants/vesselTypes",
    MAIN_ENGINE_MODEL: "api/mainEngineModel",
    MAIN_ENGINE_MANUFACTURER: "api/mainEngineManufacturer",
    AUX_ENGINE_MANUFACTURER: "api/auxEngineManufacturer",
    SHIP_MODEL: "api/shipModel",
    BUILD_DEFAULTS: "api/shipModel/buildDefaults",
    MAIN_ENGINE_DEFAULTS: "api/shipModel/mainEngineDefaults",
    CONTRACT_POINT_DEFAULTS: "api/shipModel/contractPointDefaults",
    SIMILAR_MAIN_ENGINE: "api/shipModel/mainEngine",
    PEDI_CREATE_ENDPOINT: "api/shipModel/PEDI/Create",
    VESPER_MODELS_ENDPOINT: "api/shipModel/models",
    MAIN_ENGINE_EXCEL_ENDPOINT: "api/shipModel/{imoNumber}/mainEngine/data",
    HYDROSTATIC_EXCEL_ENDPOINT: "api/shipModel/{imoNumber}/hydrostatic/data",
    VALIDATION_POINTS: "api/shipModel/validationPoints",
    IHS_SHIP_MODEL_ENDPOINT: "api/IHS",
    ADD_SISTER_ENDPOINT: "api/shipModel/{imoNumber}/sister",
};