<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card flat>
      <v-card-text>
        <v-row justify="center">
          <v-col cols="4">
            <v-row>
              <v-col class="pb-0" cols="6">
                <v-autocomplete
                    v-model="fields.meManufacturerId"
                    :items="engineManufacturer.values"
                    :loading="engineManufacturer.isLoading"
                    clearable
                    dense
                    item-text="name"
                    item-value="id"
                    label="ME Manufacturer"
                    outlined
                    @change="fields.meModel = ''"
                />
                <v-combobox
                    v-model="fields.meModel"
                    :disabled="!fields.meManufacturerId"
                    :items="manufacturerModels[fields.meManufacturerId]"
                    :loading="engineModel.isLoading"
                    dense
                    item-text="name"
                    label="ME Model"
                    outlined
                    return-object
                />
                <v-text-field
                    v-model="fields.epl"
                    :rules="constraints.noNegatives.rules"
                    dense
                    label="Power Limitation"
                    outlined
                    suffix="kW"
                ></v-text-field>
                <custom-date-picker
                    ref="datePicker"
                    label="Power Limitation Date"
                    @dateUpdated="dateUpdated"
                />
              </v-col>
              <v-col class="pb-0" cols="6">
                <v-text-field
                    v-model="fields.meMCR"
                    :class="[getColorForValue('meMCR')]"
                    :rules="constraints.noNegativesReq.rules"
                    dense
                    label="ME MCR"
                    outlined
                    suffix="kW"
                ></v-text-field>
                <v-text-field
                    v-model="fields.meSMCR"
                    :class="[getColorForValue('meSMCR')]"
                    :rules="constraints.noNegativesReq.rules"
                    dense
                    label="ME SMCR"
                    outlined
                    suffix="kW"
                ></v-text-field>
                <v-text-field
                    v-model="fields.meRPM"
                    :class="[getColorForValue('meRPM')]"
                    :rules="constraints.noNegativesReq.rules"
                    dense
                    label="ME RPM @ SMCR"
                    outlined
                    suffix="rpm"
                ></v-text-field>
                <v-text-field
                    v-model="fields.meNCR"
                    :class="[getColorForValue('meNCR')]"
                    :rules="constraints.noNegativesReq.rules"
                    dense
                    label="ME NCR"
                    outlined
                    suffix="kW"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0">
                <upload-files
                    ref="fileUpload"
                    :endpoint="uploadFilesEndpoint"
                    name="Main Engine Data Excel"
                    templatePath="files/MEData_Template.xlsm"
                ></upload-files>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-checkbox
                v-model="fields.useSFOCDefaults"
                class="pa-0"
                color="primary"
                dense
                label="Use SFOC from VPS library"
            ></v-checkbox>
            <div v-if="fields.useSFOCDefaults">
              <v-img
                  max-height="300"
                  max-width="250"
                  src="sfocPlot.png"
              >
                <template v-slot:placeholder>
                  <v-row
                      align="center"
                      class="fill-height ma-0"
                      justify="center"
                  >
                    <v-progress-circular
                        color="grey lighten-5"
                        indeterminate
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-radio-group v-model="fields.SFOCDefaultIndex">
                <v-radio
                    v-for="entry in sfocDefaults"
                    :key="entry.num"
                    :label="entry.text"
                    :value="entry.num"
                    color="primary"
                    dense
                    outlined
                ></v-radio>
              </v-radio-group>
            </div>
          </v-col>
          <v-col class="ml-auto" cols="5">
            <v-simple-table dense fixed-header height="300px">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">Vessel Name</th>
                  <th class="text-left">ME Model</th>
                  <th class="text-right">ME MCR (kW)</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, name, index) in similarShips" :key="index">
                  <td>{{ item.vesselName }}</td>
                  <td>
                    {{ item.mainEngineModel }}
                  </td>
                  <td class="text-right">
                    {{ item.mainEngineMcr.toFixed(0) }}
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
      <ihs-data-dialog
          ref="ihsDialog"
          :fieldNames="ihsFieldNames"
          :shipModel="shipModel"
          @dialogCompleted="dialogCompleted"
      />
    </v-card>
  </v-form>
</template>

<script>
import UploadFiles from "@/components/UploadFiles";
import CustomDatePicker from "@/components/CustomDatePicker";
import {getNumberRules, getRuleForElement} from "@/utils/inputRulesBuilder";
import {ModelBuilderMixin} from "@/utils/customMixins";
import {ENDPOINT, TYPES} from "@/utils/constants";
import IHSDataDialog from "@/components/IHSDataDialog";

export default {
  name: "MainEngineSpecifications",
  mixins: [ModelBuilderMixin],

  data: () => ({
    refName: "mainEngineSpecs",
    fields: {
      meManufacturerId: "",
      meModel: "",
      meMCR: "",
      meRPM: "",
      meSMCR: "",
      meNCR: "",
      useSFOCDefaults: false,
      SFOCDefaultIndex: 2,
      // epl: "",
      // eplDate: "",
    },

    similarShips: [],
    shipModelLoaded: false,

    sfocDefaults: [
      {num: 3, text: "Maximum"},
      {num: 2, text: "Average"},
      {num: 1, text: "Minimum"},
    ],

    ihsFieldNames: [
      ["meMCR", "ME MCR (kW)"],
      ["meRPM", "ME RPM (rpm)"],
      ["meSMCR", "ME SMCR (kW)"],
    ],
  }),

  methods: {
    dateUpdated(newDate) {
      this.fields.eplDate = newDate;
    },
    enableIHSDataPicker(shipModel) {
      this.$refs.ihsDialog.enableDialog(shipModel, shipModel.ihsShipModel);
    },
    dialogCompleted(dialogFields) {
      for (const field in dialogFields) {
        this.fields[field] = dialogFields[field];
      }
      this.fields.meNCR = 0;
      this.fields.meModel = this.shipModel.ihsShipModel.meModel;
      const meManufacturer = this.engineManufacturer.values.find((me) =>
          this.matchStrings(me.name, this.shipModel.ihsShipModel.meManufacturer)
      );
      this.fields.meManufacturerId = meManufacturer ? meManufacturer.id : "";
    },
    matchStrings(string1, string2) {
      if (!string1 || !string2) return false;
      string1 = string1.replace(/[-]/g, " ").replace(/[!-/:-@[-`{-~]/g, "");
      string2 = string2.replace(/[-]/g, " ").replace(/[!-/:-@[-`{-~]/g, "");
      // From https://stackoverflow.com/questions/35667843/compare-words-in-strings
      var res = string2
          .match(/\b\w+?\b/g)
          // getting words from second string
          .reduce(function (a, b) {
            return a || new RegExp("\\b" + b + "\\b", "i").test(string1);
            //checking string in first string
          }, false);
      // also set initial value as false since no strings are matched now

      return res;
    },
    setFields(data) {
      this.fields.meManufacturerId = data.meManufacturerId;
      this.fields.meModel = data.meModel;
      this.fields.meMCR = data.meMCR;
      this.fields.meRPM = data.meRPM;
      this.fields.meSMCR = data.meSMCR;
      this.fields.meNCR = data.meNCR;
      this.fields.useSFOCDefaults = data.useSFOCDefaults;
      this.fields.SFOCDefaultIndex = data.SFOCDefaultIndex;
      this.fields.epl = data.EPL;
      this.fields.eplDate = data.EPLDate;
      this.$refs.datePicker.date = data.EPLDate;
    },
    setUploadFile(file) {
      this.$refs.fileUpload.setCurrentFile(file);
    },
  },

  computed: {
    constraints() {
      return {
        required: getRuleForElement(this, TYPES.TEXT, false),
        noNegativesReq: getNumberRules(this, {
          required: true,
          range: [0, -1],
        }),
        noNegatives: getNumberRules(this, {
          required: false,
          range: [0, -1],
        }),
      };
    },
    uploadFilesEndpoint() {
      if (this.shipModelLoaded) {
        return ENDPOINT.MAIN_ENGINE_EXCEL_ENDPOINT.replace(
            "{imoNumber}",
            this.shipModel.imoNumber
        );
      }
      return "";
    },
  },

  props: {
    shipModel: Object,
    similarMainEngineShips: Array,
    manufacturerModels: Object,
    engineManufacturer: Object,
    engineModel: Object,
  },

  watch: {
    shipModel(newShipModel) {
      if (newShipModel.meMCR != null && !this.shipModelLoaded) {
        this.shipModelLoaded = true;
        this.fields.meMCR = this.shipModel.meMCR.toFixed(2);
        this.fields.meRPM = this.shipModel.meRPM.toFixed(2);
        this.fields.meSMCR = this.shipModel.meSMCR.toFixed(2);
        this.fields.meNCR = this.shipModel.meNCR.toFixed(2);
      }
    },
    similarMainEngineShips(newSimilarMainEngineShips) {
      if (newSimilarMainEngineShips != null) {
        this.similarShips = [...newSimilarMainEngineShips];
        this.similarShips = this.similarShips.sort(
            (a, b) => a.mainEngineMcr - b.mainEngineMcr
        );
      }
    },
  },

  components: {
    "ihs-data-dialog": IHSDataDialog,
    "upload-files": UploadFiles,
    "custom-date-picker": CustomDatePicker,
  },
};
</script>

<style scoped>
.non-default-field >>> .v-text-field__slot input {
  color: green;
}

.non-default-ihs-field >>> .v-text-field__slot input {
  color: blue;
}
</style>