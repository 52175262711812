<template>
  <v-snackbar
      v-model="snackbar"
      :bottom="bottom"
      :color="color"
      :left="left"
      :right="right"
      :timeout="timeout"
      :top="top"
  >
    {{ text }}

    <template v-slot:action="{ attrs }">
      <v-btn dark text v-bind="attrs" @click="snackbar = false"> Close</v-btn>
    </template>
  </v-snackbar>
</template>


<script>
export default {
  data() {
    return {
      snackbar: false,
    };
  },

  methods: {
    enableSnackbar() {
      if (!this.snackbar) {
        this.snackbar = true;
      }
    },
  },
  props: {
    color: String,
    bottom: Boolean,
    top: Boolean,
    left: Boolean,
    right: Boolean,
    text: String,
    timeout: {
      type: Number,
      default: 1500,
    },
  },
};
</script>