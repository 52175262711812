export const ChartOptions = {
    chart: {
        height: "300px",
        type: "line",
        fontFamily: "Roboto",
        animations: {
            enabled: false,
            easing: "easeinout",
            speed: 800,
            animateGradually: {
                enabled: false,
                delay: 150,
            },
            dynamicAnimation: {
                enabled: false,
                speed: 350,
            },
        },
        zoom: {
            enabled: true,
            type: "xy",
            zoomedArea: {
                fill: {
                    color: "#90CAF9",
                    opacity: 0.4,
                },
                stroke: {
                    color: "#0D47A1",
                    opacity: 0.4,
                    width: 2,
                },
                markers: {
                    size: [4, 4, 4, 0],
                    colors: ["#FFA41B", "#C9C9C9", "#303f9f", "#C9C9C9"],
                    strokeColors: "#000",
                    strokeWidth: [0, 0, 0, 1],
                    hover: {
                        size: 4,
                    },
                },
            },
        },
    },
    annotations: {
        xaxis: [],
    },
    fill: {
        type: "solid",
    },
    markers: {
        size: [2, 2, 4, 0],
        colors: ["#FFA41B", "#C9C9C9", "#303f9f", "#C9C9C9"],
        strokeColors: "#000",
        strokeWidth: [0, 0, 0, 1],
        hover: {
            size: 4,
        },
    },
    dataLabels: {
        enabled: false,
    },
    tooltip: {
        shared: false,
        intersect: true,
        /*custom: [
          ({ series, seriesIndex, dataPointIndex, w }) => {
            return '<div class="apexcharts-custom-tooltip">\n          '.concat(
              "<div>",
              "Value: <strong>",
              w.globals.series[seriesIndex][dataPointIndex].toFixed(4),
              "</strong></div>",
              "<div>IMO Number Name: <strong>",
              w.config.series[seriesIndex].data[dataPointIndex].imoNumber,
              "</strong>\n        </div></div>"
            );
          },
          ({ series, seriesIndex, dataPointIndex, w }) => {
            return '<div class="apexcharts-custom-tooltip">\n          '.concat(
              "<div>",
              "Value: <strong>",
              w.globals.series[seriesIndex][dataPointIndex].toFixed(4),
              "</strong></div>",
              "<div>IMO Number Name: <strong>",
              w.config.series[seriesIndex].data[dataPointIndex].imoNumber,
              "</strong>\n        </div></div>"
            );
          },
          ({ series, seriesIndex, dataPointIndex, w }) => {
            return '<div class="apexcharts-custom-tooltip">\n          '.concat(
              "<div><strong>Current Ship Model</strong></div><div>",
              "Value: <strong>",
              w.globals.series[seriesIndex][dataPointIndex].toFixed(4),
              "</strong></div>",
              "<div>IMO Number Name: <strong>",
              w.config.series[seriesIndex].data[dataPointIndex].imoNumber,
              "</strong>\n        </div></div>"
            );
          },
        ],*/
    },

    legend: {
        show: true,
    },
    stroke: {
        width: 2,
        curve: "straight",
        //curve: ["straight", "smooth"],
        //lineCap: "round",
    },
    xaxis: {
        type: "numeric",
        min: 0,
        max: 250000,
        //tickAmount: 24,
        //range: 10000,
        title: {
            text: "Scantling deadweight (t)",
        },
    },
    yaxis: {
        type: "numeric",
        tickAmount: 10,
        decimalsInFloat: 2,
        title: {
            text: "PEDI @ 14 kn (Whr/T.NM)",
        },
        labels: {
            minWidth: "400px",
        },
    },
};