<template>
  <v-container fluid>
    <v-row align="center">
      <v-col class="pa-1" cols="8">
        <v-card class="pa-0" outlined>
          <v-card-title class="pb-0">Main engine MCR (kW)</v-card-title>
          <apex-chart
              ref="meMCR"
              :options="chartOptions.meMCR"
              :series="series.meMCR"
              height="465"
              type="line"
          ></apex-chart>
        </v-card>
      </v-col>
      <v-col class="pa-1" cols="4">
        <v-card class="pa-0" outlined>
          <v-card-title class="pb-0">Contract Point Speed (kn)</v-card-title>
          <apex-chart
              ref="contractPointSpeed"
              :options="chartOptions.contractPointSpeed"
              :series="series.contractPointSpeed"
              height="200"
              type="line"
          ></apex-chart>
        </v-card>

        <v-card class="pa-0" outlined>
          <v-card-title class="pb-0">Contract Point Draft (m)</v-card-title>
          <apex-chart
              ref="contractPointDraft"
              :options="chartOptions.contractPointDraft"
              :series="series.contractPointDraft"
              height="200"
              type="line"
          ></apex-chart>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col class="pa-1" cols="4">
        <v-card class="pa-0" outlined>
          <v-card-title class="pb-0">LPP (m)</v-card-title>
          <apex-chart
              ref="lpp"
              :options="chartOptions.lpp"
              :series="series.lpp"
              height="200"
              type="line"
          ></apex-chart>
        </v-card>
      </v-col>
      <v-col class="pa-1" cols="4">
        <v-card class="pa-0" outlined>
          <v-card-title class="pb-0">Beam (m)</v-card-title>
          <apex-chart
              ref="beam"
              :options="chartOptions.beam"
              :series="series.beam"
              height="200"
              type="line"
          ></apex-chart>
        </v-card>
      </v-col>
      <v-col class="pa-1" cols="4">
        <v-card class="pa-0" outlined>
          <v-card-title class="pb-0">Design Draft (m)</v-card-title>
          <apex-chart
              ref="designDraft"
              :options="chartOptions.designDraft"
              :series="series.designDraft"
              height="200"
              type="line"
          ></apex-chart>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col class="pa-1" cols="4">
        <v-card class="pa-0" outlined>
          <v-card-title class="pb-0">Block coefficient</v-card-title>
          <apex-chart
              ref="cb"
              :options="chartOptions.cb"
              :series="series.cb"
              height="200"
              type="line"
          ></apex-chart>
        </v-card>
      </v-col>
      <v-col class="pa-1" cols="4">
        <v-card class="pa-0" outlined>
          <v-card-title class="pb-0">Lightweight (t)</v-card-title>
          <apex-chart
              ref="lightweight"
              :options="chartOptions.lightweight"
              :series="series.lightweight"
              height="200"
              type="line"
          ></apex-chart>
        </v-card>
      </v-col>
      <v-col class="pa-1" cols="4">
        <v-card class="pa-0" outlined>
          <v-card-title class="pb-0">Main engine SFOC (g/kWh)</v-card-title>
          <apex-chart
              ref="meSFOC"
              :options="chartOptions.meSFOC"
              :series="series.meSFOC"
              height="200"
              type="line"
          ></apex-chart>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col class="pa-1" cols="4">
        <v-card class="pa-0" outlined>
          <v-card-title class="pb-0">Boiler (T/24h)</v-card-title>
          <apex-chart
              ref="boiler"
              :options="chartOptions.boiler"
              :series="series.boiler"
              height="200"
              type="line"
          ></apex-chart>
        </v-card>
      </v-col>
      <v-col class="pa-1" cols="4">
        <v-card class="pa-0" outlined>
          <v-card-title class="pb-0">Base Load (T/24h)</v-card-title>
          <apex-chart
              ref="baseLoad"
              :options="chartOptions.baseLoad"
              :series="series.baseLoad"
              height="200"
              type="line"
          ></apex-chart>
        </v-card>
      </v-col>
      <v-col class="pa-1" cols="4">
        <v-card class="pa-0" outlined>
          <v-card-title class="pb-0">Contract Point Power</v-card-title>
          <apex-chart
              ref="contractPointPower"
              :options="chartOptions.contractPointPower"
              :series="series.contractPointPower"
              height="200"
              type="line"
          ></apex-chart>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col class="pa-1" cols="4">
        <v-card class="pa-0" outlined>
          <v-card-title class="pb-0">Height of Superstructure (m)</v-card-title>
          <apex-chart
              ref="hSuper"
              :options="chartOptions.hSuper"
              :series="series.hSuper"
              height="200"
              type="line"
          ></apex-chart>
        </v-card>
      </v-col>
      <v-col class="pa-1" cols="4">
        <v-card class="pa-0" outlined>
          <v-card-title class="pb-0">Breadth of Superstructure (m)</v-card-title>
          <apex-chart
              ref="bSuper"
              :options="chartOptions.bSuper"
              :series="series.bSuper"
              height="200"
              type="line"
          ></apex-chart>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {ChartOptions} from "@/utils/baseChart";
import {calculateTrendPoints} from "@/utils/utils";
import {ModelBuilderMixin} from "@/utils/customMixins";

export default {
  name: "ValidationPlot",
  mixins: [ModelBuilderMixin],

  created() {
    this.generateChartOptions();
    Object.keys(this.series).forEach((k) => (this.previousValues[k] = -1));
  },

  data() {
    return {
      series: {
        lpp: [],
        beam: [],
        designDraft: [],
        lightweight: [],
        cb: [],
        meMCR: [],
        meSFOC: [],
        baseLoad: [],
        boiler: [],
        contractPointDraft: [],
        contractPointSpeed: [],
        contractPointPower: [],
        hSuper: [],
        bSuper: [],
      },
      options: {
        decimalFields: ["contractPointSpeed", "boiler", "baseLoad", "cb"],
      },

      chartOptions: {
        lpp: {},
        beam: {},
        designDraft: {},
        lightweight: {},
        cb: {},
        meMCR: {},
        meSFOC: {},
        baseLoad: {},
        boiler: {},
        contractPointDraft: {},
        contractPointSpeed: {},
        contractPointPower: {},
        hSuper: {},
        bSuper: {},
      },
      mapping: {
        lpp: "scantlingDeadweight",
        beam: "scantlingDeadweight",
        designDraft: "scantlingDeadweight",
        lightweight: "scantlingDeadweight",
        cb: "scantlingDeadweight",
        meMCR: "scantlingDeadweight",
        meSFOC: "meMCR",
        baseLoad: "meMCR",
        boiler: "meMCR",
        contractPointDraft: "scantlingDeadweight",
        contractPointSpeed: "scantlingDeadweight",
        contractPointPower: "scantlingDeadweight",
        hSuper: "depth",
        bSuper: "beam",
      },
      previouslyInitialised: false,
      previousValues: {},
    };
  },

  methods: {
    getAnnotations() {
      return {
        xaxis: [
          {
            x: this.shipModel.vesselSubTypeMin,
            x2: this.shipModel.vesselSubTypeMax,
            fillColor: "#B3F7CA",
            opacity: 0.4,
            label: {
              borderColor: "#B3F7CA",
              style: {
                fontSize: "10px",
                color: "#fff",
                background: "#00E396",
                padding: {
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                },
              },
              offsetY: -10,
              text: this.shipModel.vesselSubType,
            },
          },
        ],
      };
    },

    generateChartOption(yaxislabel, xaxislabel, height = "200px") {
      const options = JSON.parse(JSON.stringify(ChartOptions));
      options.chart.zoom = false;
      //options.yaxis.title.text = yaxislabel;
      delete options.yaxis.title;
      if (xaxislabel) {
        options.xaxis.title.text = xaxislabel;
        delete options.xaxis.max;
        delete options.xaxis.min;
      }
      options.chart.height = height;

      //options.tooltip.enabled = false;
      if (this.shipModel) {
        options.annotations = this.getAnnotations();
      }

      options.yaxis.tickAmount = 8;
      options.yaxis.decimalsInFloat = 0;
      if (this.options.decimalFields.includes(yaxislabel)) {
        options.yaxis.decimalsInFloat = 2;
      }

      return options;
    },

    initSeries() {
      let newSeries = {};
      for (const entry in this.series) {
        newSeries[entry] = [
          {
            name: "VESPER Models",
            type: "scatter",
            data: [],
          },
          {
            name: "Significant Ships",
            type: "scatter",
            data: [],
          },
        ];
      }
      return newSeries;
    },

    addModelToSeries(newSeries, y, model) {
      const x = this.mapping[y];
      // Do not plot zero values
      if (model[x] == 0 || model[y] == 0) return;

      let index = 0;
      if (model.isSignificantShip) {
        index = 1;
      }

      newSeries[y][index].data.push({
        x: model[x],
        y: model[y],
        imoNumber: model.imoNumber,
      });
    },

    generateChartSeries() {
      let newSeries = this.series;

      if (!this.previouslyInitialised) {
        newSeries = this.initSeries();
        this.addVesperModels(newSeries);
      }

      for (const entry in newSeries) {
        if (this.previousValues[entry] === this.shipModel[entry]) {
          continue;
        }
        if (this.previouslyInitialised) {
          this.previousValues[entry] = this.shipModel[entry];
        }

        this.updateCurrentShipModel(newSeries, entry);
        this.calculateTrendPoints(newSeries, entry);

        this.$refs[entry].updateSeries(newSeries[entry], false);
      }

      this.series = newSeries;
      //console.log("---------------");
      if (this.VESPERModels.length != 0) {
        this.previouslyInitialised = true;
      }
    },

    addVesperModels(newSeries) {
      for (const model of this.VESPERModels) {
        this.addModelToSeries(newSeries, "lpp", model);
        this.addModelToSeries(newSeries, "beam", model);
        this.addModelToSeries(newSeries, "designDraft", model);
        this.addModelToSeries(newSeries, "lightweight", model);
        this.addModelToSeries(newSeries, "cb", model);
        this.addModelToSeries(newSeries, "meMCR", model);
        this.addModelToSeries(newSeries, "meSFOC", model);
        this.addModelToSeries(newSeries, "baseLoad", model);
        this.addModelToSeries(newSeries, "boiler", model);
        this.addModelToSeries(newSeries, "contractPointDraft", model);
        this.addModelToSeries(newSeries, "contractPointSpeed", model);
        this.addModelToSeries(newSeries, "contractPointPower", model);
        this.addModelToSeries(newSeries, "hSuper", model);
        this.addModelToSeries(newSeries, "bSuper", model);
      }
    },

    updateCurrentShipModel(newSeries, entry) {
      const x_field = this.mapping[entry];
      // let x_field = this.shipModel.scantlingDeadweight;
      newSeries[entry][2] = {
        name: "Current Model",
        type: "scatter",
        data: [
          {
            vesselName: this.shipModel.vesselName,
            imoNumber: this.shipModel.imoNumber,
            x: this.shipModel[x_field],
            y: this.shipModel[entry],
          },
        ],
      };
    },

    calculateTrendPoints(newSeries, entry) {
      const x = [];
      const y = [];

      for (const ship in newSeries[entry][0].data) {
        x.push(newSeries[entry][0].data[ship].x);
        y.push(newSeries[entry][0].data[ship].y);
      }
      for (const significantShip in newSeries[entry][1].data) {
        x.push(newSeries[entry][1].data[significantShip].x);
        y.push(newSeries[entry][1].data[significantShip].y);
      }

      const points = calculateTrendPoints(x, y);

      newSeries[entry][3] = {
        name: "Power Trend",
        type: "line",
        data: points.power,
      };
    },

    generateChartOptions() {
      this.chartOptions.lpp = this.generateChartOption("lpp");
      this.chartOptions.beam = this.generateChartOption("beam");
      this.chartOptions.designDraft = this.generateChartOption("designDraft");
      this.chartOptions.lightweight = this.generateChartOption("lightweight");
      this.chartOptions.cb = this.generateChartOption("cb");
      this.chartOptions.meMCR = this.generateChartOption(
          "meMCR",
          null,
          "465px"
      );
      this.chartOptions.meSFOC = this.generateChartOption("meSFOC", "ME MCR (kW)");
      this.chartOptions.baseLoad = this.generateChartOption("baseLoad", "ME MCR (kW)");
      this.chartOptions.boiler = this.generateChartOption("boiler", "ME MCR (kW)");
      this.chartOptions.contractPointDraft = this.generateChartOption(
          "contractPointDraft"
      );
      this.chartOptions.contractPointSpeed = this.generateChartOption(
          "contractPointSpeed"
      );
      this.chartOptions.contractPointPower = this.generateChartOption(
          "contractPointPower"
      );
      this.chartOptions.hSuper = this.generateChartOption("hSuper", "Depth (m)");
      this.chartOptions.bSuper = this.generateChartOption("bSuper", "Beam (m)");
    },

    updateChart() {
      this.generateChartSeries();
    },
  },

  props: {
    shipModel: Object,
    VESPERModels: Array,
  },
};
</script>