export function errorDisplay(snackbarRef, snackbarObj, error) {
    if (error.message) {
        if (error.message.includes('401')) {
            snackbarObj.text = 'Authentication failed!';
        } else {
            snackbarObj.text = error.message;
        }
    }

    snackbarRef.enableSnackbar();
}

export function messageDisplay(snackbarRef, snackbarObj, message) {
    snackbarObj.text = message;
    snackbarObj.color = 'info';

    snackbarRef.enableSnackbar();
}