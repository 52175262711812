<template>
  <v-dialog v-model="dialog" persistent width="600px">
    <v-card>
      <v-card-title class="headline">
        Add sister details
      </v-card-title>
      <v-card-text class="pt-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <custom-snack-bar
                ref="snackbar"
                :color="snackbar.color"
                :text="snackbar.text"
            />
            <v-row class="pt-0 pb-0">
              <v-col class="pt-0 pb-0" cols="4">
                <v-text-field
                    v-model="fields.grossTonnage"
                    :rules="constraints.noNegativesReq.rules"
                    dense
                    label="Gross tonnage"
                    outlined
                    suffix="GT"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pt-0 pb-0">
              <v-col class="pt-0 pb-0" cols="6">
                <v-switch
                    v-model="fields.torsionMeter"
                    color="primary"
                    dense
                    label="Torsion Meter Fitted"
                ></v-switch>
              </v-col>
              <v-col class="pt-0 pb-0" cols="6">
                <v-switch
                    v-model="fields.auxEngineKWhCounter"
                    color="primary"
                    dense
                    label="Aux Engine kWh Counter"
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Cancel</v-btn>
          <v-btn
              :loading="isLoading"
              color="primary"
              text
              @click.stop="completedDialog"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {addSisterVessel} from "@/utils/http-methods";
import {getNumberRules} from "@/utils/inputRulesBuilder";
import CustomSnackBar from "@/components/CustomSnackBar";

export default {
  data() {
    return {
      dialog: false,
      valid: true,
      fields: {
        torsionMeter: true,
        auxEngineKWhCounter: true,
        grossTonnage: "",
        inertGasSystem: "",
      },
      snackbar: {
        text: "",
        color: "error",
      },
      isLoading: false,
    };
  },

  methods: {
    enableDialog(sister) {
      this.dialog = true;
      this.fields.vesselType = this.shipModel.vesselType;
      this.fields.imoNumber = this.shipModel.imoNumber;
      this.fields.buildDate = this.shipModel.buildDate;
      this.fields.shipyard = this.shipModel.shipyard;
      this.fields.scantlingDeadweight = this.shipModel.scantlingDeadweight;
      this.fields.vesselName = this.shipModel.vesselName;
      this.sisterImoNumber = sister.imoNumber;
    },
    getFileName() {
      return "Ship Model " + this.shipModel.imoNumber + ".xlsm";
    },
    completedDialog() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.isLoading = true;
      addSisterVessel(this.sisterImoNumber, this.fields)
          .then((data) => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", this.getFileName());
            document.body.appendChild(link);
            link.click();

            this.snackbar.text =
                "Successfully added vessel as sister of " + this.sisterImoNumber;
            this.snackbar.color = "info";
            this.$refs.snackbar.enableSnackbar();

            this.isLoading = false;
          })
          .catch((err) => {
            this.snackbar.text =
                "There was an error while adding vessel as sister of " +
                this.sisterImoNumber;
            this.snackbar.color = "warn";
            this.$refs.snackbar.enableSnackbar();
            this.isLoading = false;

            console.log(err);
          });
      this.$emit("dialogCompleted");
    },
  },

  computed: {
    constraints() {
      return {
        noNegativesReq: getNumberRules(this, {
          required: true,
          range: [0, -1],
        }),
      };
    },
  },

  props: {
    shipModel: Object,
    fieldNames: Array,
  },
  components: {
    "custom-snack-bar": CustomSnackBar,
  },
};
</script>