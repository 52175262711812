<template>
  <v-simple-table dense fixed-header height="300px">
    <template v-slot:default>
      <thead>
      <tr>
        <slot name="tableHeader"/>
        <v-btn
            v-if="isBelowMaxRows"
            color="primary"
            icon
            @click.stop="addEmpty"
        >
          <!--<v-icon>mdi-add</v-icon>-->
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </tr>
      </thead>
      <tbody>
      <tr v-if="rows.length < 1 && noDataText">
        <td :colspan="Object.keys(fields).length" class="text-center">
          {{ noDataText }}
        </td>
      </tr>
      <tr v-for="item in rows" :key="item[tableKey]">
        <slot :item="item" name="tableBody"></slot>
        <v-btn
            v-if="isAboveMinRows"
            color="primary"
            icon
            @click.stop="removeEntry(item)"
        >
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  data() {
    return {
      valid: true,

      entryCount: 1,
      rows: [],
    };
  },

  methods: {
    addEmpty() {
      const entry = {};
      for (const [key, value] of Object.entries(this.fields)) {
        entry[key] = value;
      }
      entry[this.tableKey] = this.entryCount++;
      this.rows.push(entry);
    },
    addEntry(entry) {
      for (const [key, value] of Object.entries(this.fields)) {
        if (!entry[key]) {
          entry[key] = value;
        }
      }
      entry[this.tableKey] = this.entryCount++;
      this.rows.push(entry);
    },
    removeEntry(item) {
      const idx = this.rows.indexOf(item);
      if (idx > -1) {
        this.rows.splice(idx, 1);
      }
      let count = 1;
      this.rows.forEach((v) => (v[this.tableKey] = count++));
      this.entryCount = count;
    },
    setRows(rows) {
      for (const row of rows) {
        this.addEntry(row)
      }
    }
  },

  computed: {
    isAboveMinRows() {
      return this.range[0] == -1 || this.range[0] < this.entryCount - 1;
    },
    isBelowMaxRows() {
      return this.range[1] == -1 || this.range[1] >= this.entryCount;
    },
  },

  props: {
    tableKey: String,
    fields: Object,
    noDataText: String,
    range: {
      type: Array,
      default: () => [-1, -1],
    },
  },
};
</script>

<style scoped>
tr:nth-child(even) {
  background: #fff;
}
</style>