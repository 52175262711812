<template>
  <v-container fluid>
    <v-row justify="center">
      <!--<v-dialog v-model="dialog" persistent>-->
      <v-col :hidden="!dialog" cols="12">
        <v-card>
          <v-card-title>
            <h3>General data</h3>
          </v-card-title>
          <general-data
              ref="generalData"
              @defaultBuildSpecsRetrieved="defaultBuildSpecsRetrieved"
              @defaultContractPointSpecsRetrieved="
              defaultContractPointSpecsRetrieved
            "
              @defaultMainEngineSpecsRetrieved="defaultMainEngineSpecsRetrieved"
              @generalDataCompleted="generalDataCompleted"
              @generalInformationCompleted="generalInformationCompleted"
              @ihsDataReceived="ihsDataReceived"
              @previousShipModelDataRetrieved="previousShipModelDataRetrieved"
          />
        </v-card>
      </v-col>
      <!--</v-dialog>-->
      <v-col :hidden="dialog" cols="12">
        <v-sheet rounded="lg">
          <v-col cols="12">
            <v-row justify="center">
              <v-container fill-height fluid>
                <v-row align="center" justify="center">
                  <v-col class="pa-0">
                    <v-stepper
                        v-model="selectedStep"
                        alt-labels
                        non-linear
                        @change="stepChanged"
                    >
                      <v-stepper-header class="text-subtitle">
                        <v-container class="pa-0" fill-height fluid>
                          <v-row justify="center" no-gutters>
                            <v-col
                                align-self="center"
                                class="pa-0 d-flex justify-center align-center"
                                cols="12"
                            >
                              <v-stepper-step
                                  :editable="stepsEditable"
                                  :rules="[() => stepsValid.buildSpecs]"
                                  edit-icon=""
                                  step="1"
                                  @click="updateShipModelFields"
                              >
                                <div class="text-subtitle text-center">
                                  Build Specifications
                                </div>
                              </v-stepper-step>
                              <v-stepper-step
                                  :editable="stepsEditable"
                                  :rules="[() => stepsValid.mainEngineSpecs]"
                                  edit-icon=""
                                  step="2"
                                  @click="updateShipModelFields"
                              >
                                <div class="text-subtitle text-center">
                                  Main Engine Specifications
                                </div>
                              </v-stepper-step>
                              <v-stepper-step
                                  :editable="stepsEditable"
                                  :rules="[() => stepsValid.miscSpecifications]"
                                  edit-icon=""
                                  step="3"
                                  @click="updateShipModelFields"
                              >
                                <div class="text-subtitle text-center">
                                  Miscellaneous Specifications
                                </div>
                              </v-stepper-step>
                              <v-stepper-step
                                  :editable="stepsEditable"
                                  :rules="[() => stepsValid.contractPointSpecs]"
                                  edit-icon=""
                                  step="4"
                                  @click="updateShipModelFields"
                              >
                                <div class="text-subtitle text-center">
                                  Contract Point Specifications
                                </div>
                              </v-stepper-step>
                              <v-stepper-step
                                  :editable="stepsEditable"
                                  edit-icon=""
                                  step="5"
                                  @click="updateShipModelFields"
                              >
                                <div class="text-subtitle text-center">
                                  Validation Plot Overview
                                </div>
                              </v-stepper-step>
                              <v-stepper-step
                                  v-if="extraValidationEnabled"
                                  :editable="stepsEditable"
                                  edit-icon=""
                                  step="7"
                                  @click="updateShipModelFields"
                              >
                                <div class="text-subtitle text-center">
                                  Extended PEDI Plots
                                </div>
                              </v-stepper-step>
                              <v-stepper-step
                                  :editable="stepsEditable"
                                  edit-icon=""
                                  step="6"
                                  @click="updateShipModelFields"
                              >
                                <div class="text-subtitle text-center">
                                  Generate Ship Model
                                </div>
                              </v-stepper-step>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-stepper-header>
                      <v-stepper-items>
                        <v-stepper-content step="1">
                          <build-specifications
                              ref="buildSpecs"
                              :shipModel="shipModel"
                              :similarShips="similarShips"
                          />
                        </v-stepper-content>

                        <v-divider></v-divider>

                        <v-stepper-content step="2">
                          <main-engine-specifications
                              ref="mainEngineSpecs"
                              :engineManufacturer="data.engineManufacturer"
                              :engineModel="data.engineModel"
                              :manufacturerModels="manufacturerModels"
                              :shipModel="shipModel"
                              :similarMainEngineShips="similarMainEngineShips"
                          />
                        </v-stepper-content>

                        <v-divider></v-divider>

                        <v-stepper-content step="3">
                          <misc-specifications
                              ref="miscSpecifications"
                              :auxEngineManufacturer="data.auxEngineManufacturer"
                              :shipModel="shipModel"
                          />
                        </v-stepper-content>

                        <v-divider></v-divider>

                        <v-stepper-content step="4">
                          <contract-point-specifications
                              ref="contractPointSpecs"
                              :VESPERModels="VESPERModels"
                              :shipModel="shipModel"
                              @updateShipModelFields="updateShipModelFields"
                          />
                        </v-stepper-content>

                        <v-divider></v-divider>

                        <v-stepper-content step="5">
                          <validation-plot
                              ref="validationPlot"
                              :VESPERModels="VESPERModels"
                              :shipModel="shipModel"
                          />
                        </v-stepper-content>

                        <v-stepper-content step="6">
                          <generate-ship-model
                              ref="generateShipModel"
                              :shipModel="shipModel"
                              @validModel="validModel"
                          />
                        </v-stepper-content>

                        <v-divider></v-divider>

                        <v-stepper-content step="7">
                          <extra-validation
                              ref="extraValidation"
                              :VESPERModels="VESPERModels"
                              :shipModel="shipModel"
                          />
                        </v-stepper-content>
                      </v-stepper-items>
                    </v-stepper>
                  </v-col>
                </v-row>
              </v-container>
            </v-row>
          </v-col>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GeneralData from "@/components/modelBuilder/GeneralData";
import BuildSpecifications from "@/components/modelBuilder/BuildSpecifications";
import MainEngineSpecifications from "@/components/modelBuilder/MainEngineSpecifications";
import MiscSpecifications from "@/components/modelBuilder/MiscSpecifications";
import ValidationPlot from "@/components/modelBuilder/ValidationPlot";
import ExtraValidation from "@/components/modelBuilder/ExtraValidation";
import ContractPointSpecifications from "../components/modelBuilder/ContractPointSpecifications";
import GenerateShipModel from "../components/modelBuilder/GenerateShipModel";
import {ENDPOINT} from "@/utils/constants";
import {fetchResource, fetchSimilarMCRShips, fetchValidationPoints, fetchVESPERShipModels,} from "@/utils/http-methods";

export default {
  mounted() {
    fetchResource(
        this.data.engineManufacturer.endpoint,
        this.data.engineManufacturer
    )
        .then(() =>
            fetchResource(this.data.engineModel.endpoint, this.data.engineModel)
                .then(() => {
                  this.setupManufacturerModels();
                })
                .catch(console.log)
        )
        .catch(console.log);

    fetchResource(
        this.data.auxEngineManufacturer.endpoint,
        this.data.auxEngineManufacturer
    )
        .then()
        .catch(console.log);
  },

  data() {
    return {
      selectedStep: 1,
      completedGeneralData: true,
      ihsDataEnabled: false,
      stepsEditable: false,
      shipModel: {},
      shipModelPEDI: {},
      similarShips: [],
      similarMainEngineShips: [],
      VESPERModels: [],

      stepsValid: {
        buildSpecs: true,
        mainEngineSpecs: true,
        miscSpecifications: true,
        contractPointSpecs: true,
      },
      ihsDataPicked: {
        buildSpecs: false,
        mainEngineSpecs: false,
        contractPointSpecs: false,
      },

      data: {
        engineModel: {
          fieldName: "mainEngineModels",
          endpoint: ENDPOINT.MAIN_ENGINE_MODEL,
          isLoading: false,
          values: [],
        },
        engineManufacturer: {
          fieldName: "mainEngineManufacturers",
          endpoint: ENDPOINT.MAIN_ENGINE_MANUFACTURER,
          isLoading: false,
          values: [],
        },
        auxEngineManufacturer: {
          fieldName: "auxEngineManufacturers",
          endpoint: ENDPOINT.AUX_ENGINE_MANUFACTURER,
          isLoading: false,
          values: [],
        },
      },

      manufacturerModels: {},
      dialog: true,
      contractPointDraftUpdated: false,
      extraValidationEnabled: false,

      usePreviousModelData: true,
      previousShipModelData: {},
    };
  },

  methods: {
    ihsDataReceived(data) {
      this.shipModel.ihsShipModel = data;
    },
    previousShipModelDataRetrieved(data, usePreviousModelData) {
      this.usePreviousModelData = usePreviousModelData;
      this.previousShipModelData = data;
    },
    generalInformationCompleted() {
      if (
          this.usePreviousModelData &&
          Object.keys(this.previousShipModelData).length !== 0 &&
          Object.keys(this.previousShipModelData.shipModelData).length !== 0
      ) {
        // Temp fix
        const vesselTypeName = this.shipModel.vesselTypeName;
        const vesselType = this.shipModel.vesselType;
        this.shipModel = {...this.previousShipModelData.shipModelData};
        this.shipModel.vesselTypeName = vesselTypeName;
        this.shipModel.vesselType = vesselType;

        this.$refs.buildSpecs.setFields(
            this.previousShipModelData.shipModelData
        );
        this.$refs.mainEngineSpecs.setFields(
            this.previousShipModelData.shipModelData
        );
        this.$refs.miscSpecifications.setFields(
            this.previousShipModelData.shipModelData
        );
        this.$refs.contractPointSpecs.setFields(
            this.previousShipModelData.shipModelData
        );
        this.$refs.generateShipModel.setFields(
            this.previousShipModelData.shipModelData
        );
      }

      this.$refs.mainEngineSpecs.setUploadFile(
          this.previousShipModelData.mainEngineDataFile
      );
      this.$refs.contractPointSpecs.setUploadFile(
          this.previousShipModelData.hydrostaticDataFile
      );
    },
    generalDataCompleted(data, ihsDataEnabled) {
      this.shipModel = Object.assign({}, this.shipModel, data);
      this.ihsDataEnabled = ihsDataEnabled;
      if (ihsDataEnabled) {
        this.shipModel.ihsShipModel.auxEngines.forEach((auxEngine) => {
          this.$refs.miscSpecifications.addAuxEngineRow(auxEngine);
        });
      }
    },
    defaultBuildSpecsRetrieved(data) {
      this.similarShips = data.similarShips;
      delete data["similarShips"];
      this.shipModel = Object.assign({}, this.shipModel, data);

      this.dialog = false;
      this.completedGeneralData = true;
      this.stepsEditable = true;
      this.stepChanged(this.selectedStep);
    },
    defaultMainEngineSpecsRetrieved(data) {
      this.shipModel = Object.assign({}, this.shipModel, data);

      fetchVESPERShipModels({VesselType: this.shipModel.vesselType})
          .then((data) => {
            this.VESPERModels = data.shipModelModels;
            // If vessels have a build date, it means admin requested the data so we display the extra validation plot
            if (this.VESPERModels && this.VESPERModels.some(m => new Date(m.buildDate) > new Date(0))) {
              this.extraValidationEnabled = true;
            }
            fetchSimilarMCRShips(this.shipModel.vesselType, this.shipModel.meMCR)
                .then((data) => {
                  this.similarMainEngineShips = data.similarMainEngineShips;
                })
                .catch(console.log);
          })
          .catch(console.log);
    },
    defaultContractPointSpecsRetrieved(data) {
      this.shipModel = Object.assign({}, this.shipModel, data);
    },
    updateShipModelFields() {
      if (!this.completedGeneralData) return;
      if (!this.stepsEditable) return;

      this.shipModel = Object.assign(
          {},
          this.shipModel,
          this.$refs.buildSpecs.shipModelLoaded
              ? this.$refs.buildSpecs.fields
              : {},
          this.$refs.mainEngineSpecs.shipModelLoaded
              ? this.$refs.mainEngineSpecs.fields
              : {},
          this.$refs.miscSpecifications.getFields(),
          this.$refs.contractPointSpecs.shipModelLoaded
              ? this.$refs.contractPointSpecs.fields
              : {}
      );
      // TODO: Improve this logic
      this.shipModelPEDI = this.$refs.contractPointSpecs.shipModelPEDI;

      this.updateShipModelCalculatedData();

      this.$refs.validationPlot.updateChart();
      if (this.VESPERModels && this.VESPERModels.some(m => m.buildDate != null)) {
        this.$refs.extraValidation.updateChart(this.shipModelPEDI);
      }

      this.checkSteps();
    },
    updateShipModelCalculatedData() {
      // TODO: Maybe move this logic to backend
      this.shipModel.cb =
          (this.shipModel.designDeadweight + this.shipModel.lightweight) /
          (this.shipModel.lpp *
              this.shipModel.beam *
              this.shipModel.designDraft *
              1.025);

      this.shipModel.contractPointPower =
          this.shipModel.meNCR / (1 + this.shipModel.serviceMargin / 100);
      if (this.shipModel.twinSkegWithTwinShaft) {
        this.shipModel.contractPointPower =
            this.shipModel.contractPointPower * 2;
      }
    },
    checkSteps() {
      this.stepsValid.buildSpecs = this.$refs.buildSpecs.isValid();
      this.stepsValid.mainEngineSpecs = this.$refs.mainEngineSpecs.valid;
      this.stepsValid.miscSpecifications = this.$refs.miscSpecifications.valid;
      this.stepsValid.contractPointSpecs = this.$refs.contractPointSpecs.valid;

      return (
          this.stepsValid.buildSpecs &&
          this.stepsValid.mainEngineSpecs &&
          this.stepsValid.miscSpecifications &&
          this.stepsValid.contractPointSpecs
      );
    },

    validModel() {
      this.updateShipModelFields();

      let valid = this.checkSteps();

      this.$refs.generateShipModel.validationResult(valid);
    },

    stepChanged(step) {
      // Build specs
      if (step == 1) {
        this.checkIHSDialog("buildSpecs");
      }
      if (step == 2) {
        this.checkIHSDialog("mainEngineSpecs");
      }
      if (step == 4) {
        this.checkIHSDialog("contractPointSpecs");
        this.updateContractPointDraft();
      }
      if (step == 5) {
        fetchValidationPoints(this.shipModel)
            .then((data) => {
              this.shipModel = Object.assign({}, this.shipModel, data);
            })
            .catch(console.log);
      }
      if (step == 6) {
        this.$refs.generateShipModel.generateFilename();
      }
    },

    updateContractPointDraft() {
      if (
          !this.usePreviousModelData &&
          !this.contractPointDraftUpdated &&
          this.$refs.buildSpecs.fields.designDraft !=
          this.$refs.contractPointSpecs.fields.contractPointDraft
      ) {
        this.$refs.contractPointSpecs.fields.contractPointDraft = this.$refs.buildSpecs.fields.designDraft;
        this.$refs.contractPointSpecs.updateShipModelPEDI();
        this.contractPointDraftUpdated = true;
      }
    },

    checkIHSDialog(caller) {
      if (!this.ihsDataEnabled) return;

      if (caller == "buildSpecs" && !this.ihsDataPicked.buildSpecs) {
        this.$refs.buildSpecs.enableIHSDataPicker(this.shipModel);
        this.ihsDataPicked.buildSpecs = true;
      }
      if (caller == "mainEngineSpecs" && !this.ihsDataPicked.mainEngineSpecs) {
        this.$refs.mainEngineSpecs.enableIHSDataPicker(this.shipModel);
        this.ihsDataPicked.mainEngineSpecs = true;
      }
      if (
          caller == "contractPointSpecs" &&
          !this.ihsDataPicked.contractPointSpecs
      ) {
        this.$refs.contractPointSpecs.enableIHSDataPicker(this.shipModel);
        this.ihsDataPicked.contractPointSpecs = true;
      }
    },

    setupManufacturerModels() {
      this.manufacturerModels = {};

      for (const manufacturer of this.data.engineManufacturer.values) {
        this.data.engineModel.values
            .filter((model) => model.manufacturerId == manufacturer.id)
            .forEach((model) => {
              if (!this.manufacturerModels[manufacturer.id]) {
                this.manufacturerModels[manufacturer.id] = [];
              }
              this.manufacturerModels[manufacturer.id].push(model.name);
            });
      }
    },
  },

  components: {
    "general-data": GeneralData,
    "build-specifications": BuildSpecifications,
    "main-engine-specifications": MainEngineSpecifications,
    "misc-specifications": MiscSpecifications,
    "contract-point-specifications": ContractPointSpecifications,
    "validation-plot": ValidationPlot,
    "extra-validation": ExtraValidation,
    "generate-ship-model": GenerateShipModel,
  },
};
</script>

<style>
.v-stepper__step__step {
  display: none !important;
}
</style>