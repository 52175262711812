<template>
  <v-dialog v-model="dialog" persistent width="600px">
    <v-card>
      <v-card-title class="headline">
        Select vessel to load
      </v-card-title>
      <v-card-text class="pt-4">
        Multiple vessels with same IMO Number found
        <v-container>
          <v-row class="pt-0 pb-0">
            <v-col class="pt-0 pb-0" cols="4">
              <v-radio-group v-model="selectedShipModel">
                <v-radio
                    v-if="!isFirstModelEmpty"
                    :label="userShipModel.clientName"
                    :value="userShipModel"
                    color="primary"
                ></v-radio>
                <v-radio
                    v-for="shipModel in shipModels"
                    :key="shipModel.clientId"
                    :label="shipModel.clientName"
                    :value="shipModel"
                    color="primary"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
        <p v-if="!isFirstModelEmpty" class="red--text">
          {{ selectedNotDefaultWarn }}
        </p>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              :loading="isLoading"
              color="primary"
              text
              @click.stop="completedDialog"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
//import CustomSnackBar from "@/components/CustomSnackBar";

export default {
  data() {
    return {
      dialog: false,
      isLoading: false,
      isFirstModelEmpty: false,
      selectedShipModel: {},
      userShipModel: {},
      shipModels: [],
    };
  },
  methods: {
    enableDialog(shipModels) {
      this.dialog = true;

      this.userShipModel = shipModels[0];
      this.shipModels = shipModels.slice(1); // Delete first shipModel, as is manually added

      if (this.userShipModel.clientName == null) {
        // The admin user has not built any model
        this.isFirstModelEmpty = true;
      } else {
        this.userShipModel = shipModels[0];
      }
      this.selectedShipModel = this.userShipModel;
    },
    completedDialog() {
      this.$emit("shipModelSelected", this.selectedShipModel);
      this.dialog = false;
    },
  },
  computed: {
    selectedNotDefaultWarn() {
      if (
          this.selectedShipModel &&
          this.selectedShipModel.clientName != this.userShipModel.clientName
      )
        return `Current stored ship model for user ${this.shipModels[0].clientName} will be overwritten with ${this.selectedShipModel.clientName}`;
      return "";
    },
  },
};
</script>