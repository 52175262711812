import {TYPES} from "@/utils/constants";

/*
 * This method maps all the input validation rules and returns and object.
 *
 * @param self {object} The object used to display the input error
 * @param dataType {(string|array)} Use the parameters as the type of data to validate
 * @param optional {boolean} Mark a field as required
 * @param [maxLength] {number} The maximum length that the field can have
 * @param [extra] {array} Other validations for edge use cases
 *
 * @return {object}
 */
export function getRuleForElement(
    self,
    dataType,
    optional = true,
    maxLength,
    extra
) {
    let element = {
        rules: [],
    };
    if (!optional) {
        element.rules.push((v) => !!v || self.$t("errReqField"));
    }

    if (typeof maxLength === "function") {
        extra = maxLength;
    }

    if (!isNaN(maxLength) && maxLength > 0) {
        element.maxLength = maxLength;
        if (optional) {
            element.rules.push(
                (v) =>
                    !v ||
                    String.valueOf(v).length <= element.maxLength ||
                    self.$t("errNameLength", {max: element.maxLength})
            );
        } else {
            element.rules.push(
                (v) =>
                    (v && v.length <= element.maxLength) ||
                    self.$t("errNameLength", {max: element.maxLength})
            );
        }
    }

    //Check if more than one dataType has been sent and add all the rules
    if (Array.isArray(dataType)) {
        for (let i = 0; i < dataType.length; i++) {
            addTypeSpecificRules(element, self, dataType[i]);
        }
    } else {
        addTypeSpecificRules(element, self, dataType);
    }

    if (extra) {
        element.rules.push(extra);
    }

    return element;
}

export function getNumberRules(
    self,
    options = {required: false, range: [-1, -1], extra: [], greaterNotEqual: false}
) {
    let element = {
        rules: [],
    };
    if (options.required) {
        element.rules.push((v) => !!v || self.$t("errReqField"));
    }

    if (options.range[0] != -1) {
        if (options.greaterNotEqual) {
            element.rules.push((v) => !v || v > options.range[0] || self.$t("errSmallVal"));
        } else {
            element.rules.push((v) => !v || v >= options.range[0] || self.$t("errSmallVal"));
        }
    }

    if (options.range[1] != -1) {
        element.rules.push((v) => !v || v <= options.range[1] || self.$t("errLargeVal"));
    }

    if (options.extra) {
        element.rules.push(...options.extra);
    }

    return element;
}

function addTypeSpecificRules(element, self, dataType) {
    switch (dataType) {
        case TYPES.ELEMENT:
            element.rules.push((v) => v != null || self.$t("errReqField"));
            break;
        case TYPES.NUMBER:
            element.rules.push((v) => !v || !isNaN(v) || self.$t("errNotANum"));
            break;
        case TYPES.EMAIL:
            element.rules.push(
                (v) => !v || /\S+@\S+\.\S+/.test(v) || self.$t("errNotValidMail")
            );
    }
}