<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <b>Specify calibration consumption</b>
      <v-btn
          class="ma-2"
          color="primary"
          icon
          outlined
          v-bind="attrs"
          v-on="on"
      >
        <v-icon>mdi-format-list-bulleted-square</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-text class="pt-4">
        <v-container>
          <v-row>
            <v-col v-for="item in entries" :key="item.name" cols="4">
              <h3 class="mb-4">{{ item.name }}</h3>
              <custom-data-table
                  :ref="item.key"
                  :fields="tables.miscTable.fields"
                  :tableKey="tables.miscTable.tableKey"
                  noDataText="Use defaults"
              >
                <template v-slot:tableHeader>
                  <th class="text-right">ME MCR Load (%)</th>
                  <th class="text-right">Consumption</th>
                </template>
                <template v-slot:tableBody="slotProps">
                  <td>
                    <v-text-field
                        v-model="slotProps.item.load"
                        :rules="constraints.percent.rules"
                        dense
                        single-line
                        suffix="%"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                        v-model="slotProps.item.value"
                        :rules="constraints.noNegativesReq.rules"
                        dense
                        single-line
                        suffix="T/24h"
                    ></v-text-field>
                  </td>
                </template>
              </custom-data-table>
            </v-col>
            <v-col cols="4">
              <v-text-field
                  v-model="fields.boilerIdleCons"
                  dense
                  label="Boiler Idle Consumption"
                  outlined
                  suffix="T/24h"
              ></v-text-field>
              <v-text-field
                  v-model="fields.baseLoadIdleCons"
                  dense
                  label="Base Load Idle Consumption"
                  outlined
                  suffix="T/24h"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialog = false">Close</v-btn>
          </v-card-actions>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomDataTable from "@/components/CustomDataTable";
import {getNumberRules} from "@/utils/inputRulesBuilder";

export default {
  data() {
    return {
      valid: true,
      dialog: false,

      tables: {
        miscTable: {
          tableKey: "id",
          fields: {
            id: 0,
            load: "",
            value: "",
          },
        },
      },
      entries: [
        {
          key: "baseloadCons",
          name: "Baseload Consumption",
        },
        {
          key: "boilerCons",
          name: "Boiler Consumption",
        },
      ],
      fields: {
        //boilerIdleCons: "",
        //baseLoadIdleCons: "",
      },
    };
  },

  methods: {
    setFields(data) {
      this.fields.boilerIdleCons = data.boilerIdleCons;
      this.fields.baseLoadIdleCons = data.baseLoadIdleCons;

    }
  },

  computed: {
    constraints() {
      return {
        percent: getNumberRules(this, {required: true, range: [0, 100]}),
        noNegativesReq: getNumberRules(this, {
          required: true,
          range: [0, -1],
        }),
      };
    },
  },

  components: {
    "custom-data-table": CustomDataTable,
  },

  props: {},

  watch: {
    dialog(newVal) {
      if (!newVal) {
        for (const entry of this.entries) {
          this.fields[entry.key] = this.$refs[entry.key][0].rows;
        }
      }
    },
  },
};
</script>