<template>
  <v-col cols="10">
    <v-sheet rounded="lg">
      <login/>
    </v-sheet>
  </v-col>
</template>

<script>
// import HelloWorld from '../components/HelloWorld'
import Login from "@/components/Login";

export default {
  name: "LoginView",

  data: () => ({
    hidden: false,
  }),

  components: {
    login: Login,
  },
};
</script>