<template>
  <v-container fluid>
    <v-row align="center">
      <v-col class="pa-1" cols="12">
        <v-card class="pa-0" outlined>
          <v-card-title class="pb-0">Build date {{ shipModelVesselSubtype }}</v-card-title>
          <apex-chart
              ref="buildDate"
              :options="chartOptions.buildDate"
              :series="series.buildDate"
              height="465"
              type="line"
          ></apex-chart>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {ChartOptions} from "@/utils/baseChart";
// import {calculateTrendPoints} from "@/utils/utils";
import {ModelBuilderMixin} from "@/utils/customMixins";
import {calculateTrendPoints} from "@/utils/utils";

export default {
  name: "ExtraValidation",
  mixins: [ModelBuilderMixin],

  created() {
    this.generateChartOptions();
  },

  data() {
    return {
      series: {
        buildDate: [],
      },
      options: {
        decimalFields: ["buildDate"],
      },

      chartOptions: {
        buildDate: {},
      },
      mapping: {
        buildDate: "PEDI",
      },

      shipModelPEDI: {},
      previouslyInitialised: false,
    };
  },

  computed: {
    shipModelVesselSubtype() {
      if (!this.shipModel) return "";
      return `of ${this.shipModel.vesselTypeName} ${this.shipModel.vesselSubType}`;
    }
  },

  methods: {
    generateChartOptions() {
      this.chartOptions.buildDate = this.generateChartOption("PEDI @ 14 kn (Whr/T.NM)", "Build date");
    },

    generateChartOption(yAxisLabel, xAxisLabel, height = "200px") {
      const options = JSON.parse(JSON.stringify(ChartOptions));
      options.chart.zoom.type = "x";
      options.yaxis.title.text = yAxisLabel;
      //delete options.yaxis.title;
      if (xAxisLabel) {
        options.xaxis.title.text = xAxisLabel;
        options.xaxis.type = "datetime";
        //options.xaxis.max = new Date().getTime();
        //options.xaxis.min = new Date(2013, 11, 28).getTime();
        delete options.xaxis.max;
        delete options.xaxis.min;
        //options.xaxis.tickAmount = 50;
      }
      options.chart.height = height;

      //options.tooltip.enabled = false;

      options.yaxis.tickAmount = 8;
      // options.yaxis.min = 2;
      // options.yaxis.max = 2;

      options.tooltip.custom = [
        this.generateChartOptionFunc,
        this.generateChartOptionFunc,
        this.generateChartOptionFunc,
      ]

      return options;
    },

    generateChartOptionFunc({series, seriesIndex, dataPointIndex, w}) {
      return '<div class="apexcharts-custom-tooltip">\n          '.concat(
          "<div>Vessel Source: <strong>",
          w.config.series[seriesIndex].name,
          "</strong> </div>",
          "<div>IMO Number: <strong>",
          w.config.series[seriesIndex].data[dataPointIndex].imoNumber,
          "</strong>        </div>",
          "<div>Build date: <strong>",
          new Date(w.config.series[seriesIndex].data[dataPointIndex].x).toISOString().split('T')[0],
          "</strong>        </div>",
          "<div>PEDI: <strong>",
          w.globals.series[seriesIndex][dataPointIndex].toFixed(4),
          "</strong> Whr/T.NM\n</div></div>",
      );
    },

    generateChartSeries() {

      if (!this.previouslyInitialised) {
        this.initSeries();
        let matchingSubVesselVesperModels = this.getMatchingSubVesselVesperModels();
        this.addVesperModels(matchingSubVesselVesperModels);
      }

      for (const entry in this.series) {
        this.updateCurrentShipModel(entry);
        this.calculateTrendPoints(entry);
      }

      //console.log("---------------");
      if (this.VESPERModels.length !== 0) {
        this.previouslyInitialised = true;
      }
    },

    calculateTrendPoints(entry) {
      const x = [];
      const y = [];

      for (const ship in this.series[entry][0].data) {
        x.push(new Date(this.series[entry][0].data[ship].x).getTime());
        y.push(this.series[entry][0].data[ship].y);
      }
      for (const significantShip in this.series[entry][1].data) {
        x.push(new Date(this.series[entry][1].data[significantShip].x).getTime());
        y.push(this.series[entry][1].data[significantShip].y);
      }

      const points = calculateTrendPoints(x, y);

      this.series[entry][3] = {
        name: "Power Trend",
        type: "line",
        data: points.power,
      };
    },

    initSeries() {
      for (const entry in this.series) {
        this.series[entry] = [
          {
            name: "VESPER Models",
            type: "scatter",
            data: [],
          },
          {
            name: "Significant Ships",
            type: "scatter",
            data: [],
          },
          {
            name: "Current Model",
            type: "scatter",
            data: [],
          }
        ];
      }
    },

    updateCurrentShipModel(entry) {
      this.series[entry][2].data = [];
      this.series[entry][2].data.push(this.generateDataForCurrentEntry(entry));
      this.$refs.buildDate.updateSeries(this.series[entry])
    },

    generateDataForCurrentEntry(entry) {
      // console.log("Model current", entry, this.shipModelPEDI["PEDI_14"]);
      let dataEntry = {
        vesselName: this.shipModel.vesselName,
        imoNumber: this.shipModel.imoNumber,
      };
      if (entry === "buildDate") {
        dataEntry.x = this.shipModel["buildDate"];
        dataEntry.y = this.shipModelPEDI["PEDI_14"];
      }

      return dataEntry;
    },

    addModelToSeries(newSeries, entry, model) {
      let index = 0;
      if (model.isSignificantShip) {
        index = 1;
      }

      let dataEntry = {
        imoNumber: model.imoNumber,
      };
      if (entry === "buildDate") {
        // Do not use dates older than 25 years
        const year = model["buildDate"].substring(0, 4);
        if (year < (new Date()).getFullYear() - 25) {
          dataEntry.x = 0;
        } else {
          dataEntry.x = model["buildDate"];
        }
        dataEntry.y = model["pediValues"][3];
      }

      // Do not plot zero values
      if (dataEntry.x != 0 && dataEntry.y != 0) {
        newSeries[entry][index].data.push(dataEntry)
      }
    },

    addVesperModels(matchingSubVesselVesperModels) {
      for (const model of matchingSubVesselVesperModels) {
        this.addModelToSeries(this.series, "buildDate", model);
      }
    },

    getMatchingSubVesselVesperModels() {
      let matchingSubVesselVesperModels = []
      if (!this.shipModel) return matchingSubVesselVesperModels;

      for (const model of this.VESPERModels) {
        if (model.scantlingDeadweight <= this.shipModel.vesselSubTypeMax && model.scantlingDeadweight >= this.shipModel.vesselSubTypeMin) {
          matchingSubVesselVesperModels.push(model);
        }
      }
      return matchingSubVesselVesperModels;
    },

    updateChart(shipModelPEDI = null) {
      if (shipModelPEDI != null) {
        this.shipModelPEDI = shipModelPEDI;
      }
      this.generateChartSeries();
    },
  },

  props: {
    shipModel: Object,
    VESPERModels: Array,
  },
}
</script>