<template>
  <v-navigation-drawer v-model="drawer" app temporary width="300px">
    <template v-slot:prepend>
      <div class="pa-2 pb-3">
        <h3>
          Logged in as: <b>{{ username }}</b>
        </h3>
      </div>
    </template>

    <v-divider></v-divider>

    <v-list-item>
      Welcome to the VESPER Model Creation Tool, brought to you by Vessel
      Performance Solutions!
    </v-list-item>

    <template v-slot:append>
      <div class="pa-2">
        <a href="/docs/html/index.html" rel="noopener" target="_blank">
          <v-btn block>
            Open help
          </v-btn>
        </a>
      </div>
      <div class="pa-2">
        <v-btn block @click.stop="logout">
          Logout
          <v-icon class="ml-1">mdi-logout</v-icon>
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import {getUsername, logout} from "@/utils/session";

export default {
  data() {
    return {
      drawer: false,
    };
  },

  computed: {
    username() {
      return getUsername();
    },
  },

  methods: {
    enableDrawer() {
      this.drawer = true;
    },
    logout() {
      logout();
      this.$router.push({name: "Login"});
      this.$router.go();
    },
  },
};
</script>