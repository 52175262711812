<template>
  <v-dialog v-model="dialog" width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" outlined v-bind="attrs" v-on="on">
        CII Correction Factors
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <v-container>
          <v-row align="center" class="pt-4" justify="center">
            <h3 class="mb-4">CII Correction Factors</h3>
            <b>All values entered must be as per Approved Class Documents.</b>
          </v-row>
          <!-- Row 1 -->
          <v-row align="center" justify="center">
            <v-col class="pb-0" cols="6">
              <v-text-field
                  v-model="fields.eedi"
                  :rules="constraints.noNegatives.rules"
                  dense
                  label="EEDI"
                  outlined
                  suffix="g CO₂/T-NM"
              >
                <template v-slot:append>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <p class="pa-0 mb-1">
                      As per Class Approved Document
                    </p>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>

            <!-- Tanker or Gas Carrier -->
            <v-col v-if="isTankerOrGasCarrier" class="pt-4" cols="6">
              <v-text-field
                  v-model="fields.fcCorrection"
                  :rules="constraints.noNegatives.rules"
                  dense
                  label="Fc Correction for Chemical Tanker"
                  outlined
              >
                <template v-slot:append>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <p class="pa-0 mb-1">
                      As per Class Approved Document
                    </p>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>

            <!-- Bulk -->
            <v-col v-if="isBulk" class="pt-0 pb-0" cols="6">
              <v-switch
                  v-model="fields.cranesFitted"
                  class="mt-0 mb-0"
                  color="primary"
                  dense
                  hide-details
                  label="Cranes Fitted Onboard"
              ></v-switch>
            </v-col>

            <!-- Container -->
            <v-col v-if="isContainer" class="pt-0 pb-0" cols="6">
              <v-text-field
                  v-model="fields.reefer"
                  :rules="constraints.noNegatives.rules"
                  dense
                  label="Reefer Plugs"
                  outlined
              >
              </v-text-field>
            </v-col>
          </v-row>

          <!-- Row 2 -->
          <v-row align="center" justify="center">
            <v-col class="pt-0 pb-0" cols="6">
              <v-text-field
                  v-model="fields.eexi"
                  :rules="constraints.noNegatives.rules"
                  dense
                  label="EEXI"
                  outlined
                  suffix="g CO₂/T-NM"
              >
                <template v-slot:append>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <p class="pa-0 mb-1">
                      As per Class Approved Document
                    </p>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>

            <!-- Tanker or Gas Carrier + LNG -->
            <v-col
                v-if="isTankerOrGasCarrier || isLNG"
                class="pt-0 pb-0"
                cols="6"
            >
              <v-switch
                  v-model="fields.reliquefactionSystemFitted"
                  class="mt-0 mb-0"
                  color="primary"
                  dense
                  hide-details
                  label="Reliquefaction System Fitted"
              ></v-switch>
            </v-col>
            <v-col
                v-else-if="isBulk && fields.cranesFitted"
                class="pt-0 pb-0"
                cols="6"
            >
              <v-text-field
                  v-model="fields.fiVSE"
                  :rules="constraints.noNegatives.rules"
                  dense
                  label="FiVSE"
                  outlined
              >
              </v-text-field>
            </v-col>

            <v-col v-else cols="6"></v-col>
          </v-row>

          <!-- Row 3 -->
          <v-row align="center" justify="center">
            <v-col class="" cols="6">
              <v-switch
                  v-model="fields.isIceClass"
                  class="mt-0 mb-0"
                  color="primary"
                  dense
                  hide-details
                  label="Ice Class"
              ></v-switch>
            </v-col>

            <!-- Tanker or Gas Carrier + LNG -->
            <v-col
                v-if="isTankerOrGasCarrier || isLNG"
                class="pt-0 pb-0"
                cols="6"
            >
              <v-switch
                  v-model="fields.coolingSystemFitted"
                  class="mt-0 mb-0"
                  color="primary"
                  dense
                  hide-details
                  label="Cooling System Fitted"
              ></v-switch>
            </v-col>
            <v-col v-else cols="6"></v-col>
          </v-row>

          <v-row align="center" justify="center">
            <v-col v-if="fields.isIceClass" class="pt-0 pb-0" cols="6">
              <v-text-field
                  v-model="fields.fmCorrection"
                  :rules="constraints.noNegatives.rules"
                  dense
                  label="Fm Correction"
                  outlined
              >
                <template v-slot:append>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <p class="pa-0 mb-1">
                      For Ice Class IA/IA Super Vessels
                    </p>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>

            <v-col class="pt-0 pb-0" cols="6"></v-col>

            <v-col v-if="fields.isIceClass" class="pt-0 pb-0" cols="6">
              <v-text-field
                  v-model="fields.fiCorrection"
                  :rules="constraints.noNegatives.rules"
                  dense
                  label="Fi Correction"
                  outlined
              >
                <template v-slot:append>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <p class="pa-0 mb-1">
                      For Ice Class IB/IC Vessels
                    </p>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>

            <v-col class="pt-0 pb-0" cols="6"></v-col>

            <v-col class="pt-0 pb-0" cols="6">
              <v-text-field
                  v-model="fields.mainEngineSFOC"
                  :rules="constraints.mainEngineSFOC.rules"
                  dense
                  label="Main Engine SFOC"
                  outlined
                  suffix="g/kWhr"
              >
                <template v-slot:append>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <p class="pa-0 mb-1">
                      As per EEDI/EEXI/NOₓ File
                    </p>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>

            <v-col
                v-if="isTankerOrGasCarrier || isLNG"
                class="pt-0 pb-0"
                cols="6"
            >
              <v-switch
                  v-model="fields.isShuttleTanker"
                  class="mt-0 mb-0"
                  color="primary"
                  dense
                  hide-details
                  label="Vessel is Shuttle Tanker"
              >
                <template v-slot:append>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <p class="pa-0 mb-1">
                      As per Class Approved Document
                    </p>
                  </v-tooltip>
                </template>
              </v-switch>
            </v-col>
            <v-col v-else class="pt-0 pb-0" cols="6"></v-col>

            <v-col class="pt-0 pb-0" cols="6">
              <v-text-field
                  v-model="fields.auxEngineSFOC"
                  :rules="constraints.auxEngineSFOC.rules"
                  dense
                  label="Aux Engine SFOC"
                  outlined
                  suffix="g/kWhr"
              >
                <template v-slot:append>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <p class="pa-0 mb-1">
                      As per EEDI/EEXI/NOₓ File
                    </p>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>

            <v-col class="pt-0 pb-0" cols="6"></v-col>

            <v-col class="pt-0 pb-0" cols="6">
              <v-autocomplete
                  v-model="fields.cargoPumpsFitted"
                  :items="table.cargoPumpsFitted"
                  dense
                  hide-no-data
                  label="Cargo Pumps Fitted"
                  outlined
              ></v-autocomplete>
            </v-col>

            <v-col class="pt-0 pb-0" cols="6"></v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {getNumberRules} from "@/utils/inputRulesBuilder";

export default {
  data() {
    return {
      dialog: false,
      fields: {
        eedi: 0,
        eexi: 0,
        isIceClass: false,
        mainEngineSFOC: 170,
        auxEngineSFOC: 200,
        cargoPumpsFitted: "None",
        fmCorrection: 1,
        fiCorrection: 1,

        fcCorrection: 1,
        fiVSE: 1,
        cranesFitted: false,
      },
      table: {
        cargoPumpsFitted: [
          "None",
          "Steam",
          "Electrical",
          "Direct driven",
          "Hydraulic",
        ],
      },
    };
  },
  props: {
    vesselTypeName: String,
  },

  methods: {
    getFields() {
      let toReturn = this.fields;
      if (this.fields.isIceClass) {
        toReturn = Object.assign({}, toReturn, this.iceClassFields);
      }
      return toReturn;
    },
    setFields(data) {
      if (!data.cii) return;

      this.fields.eedi = data.cii.Eedi;
      this.fields.eexi = data.cii.Eexi;
      this.fields.isIceClass = data.cii.isIceClass;
      this.fields.fmCorrection = data.cii.fmCorrection;
      this.fields.fiCorrection = data.cii.fiCorrection;
      this.fields.mainEngineSFOC = data.cii.mainEngineSFOC;
      this.fields.auxEngineSFOC = data.cii.auxEngineSFOC;
      this.fields.cargoPumpsFitted = data.cii.cargoPumpsFitted;

      this.fields.fiVSE = data.cii.fiVSE;
      this.fields.fcCorrection = data.cii.fcCorrection;
      this.fields.reliquefactionSystemFitted =
          data.cii.reliquefactionSystemFitted;
      this.fields.coolingSystemFitted = data.cii.coolingSystemFitted;
      this.fields.isShuttleTanker = data.cii.isShuttleTanker;

      this.fields.cranesFitted = data.cii.cranesFitted;
      this.fields.reefer = data.cii.reefer;
    },
  },

  computed: {
    isTankerOrGasCarrier() {
      return (
          this.vesselTypeName == "Tanker" || this.vesselTypeName == "Gas Carrier"
      );
    },
    isLNG() {
      return this.vesselTypeName == "LNG Carrier";
    },
    isBulk() {
      return this.vesselTypeName == "Bulk Carrier";
    },
    isContainer() {
      return this.vesselTypeName == "Container Ship";
    },
    constraints() {
      return {
        noNegatives: getNumberRules(this, {
          required: false,
          range: [0, -1],
        }),
        mainEngineSFOC: getNumberRules(this, {
          required: false,
          range: [155, -1],
        }),
        auxEngineSFOC: getNumberRules(this, {
          required: false,
          range: [160, -1],
        }),
        foreAft: getNumberRules(this, {
          required: false,
          range: [-3, 3],
        }),
      };
    },
  },
};
</script>