import {SESSION_INFO} from '@/utils/constants';

var userData = {};

function userDataEmpty() {
    return Object.keys(userData) == 0;
}

function updateUserData() {
    let session = JSON.parse(
        localStorage.getItem(SESSION_INFO.SESSION_INFO) || '{}'
    );
    userData = session;
}

export function getUsername() {
    if (isUserLoggedIn()) {
        return userData[SESSION_INFO.USERNAME];
    }
    return null;
}

export function getUserToken() {
    if (isUserLoggedIn()) {
        return userData[SESSION_INFO.TOKEN];
    }
    return null;
}

export function isUserLoggedIn() {
    if (userDataEmpty()) {
        updateUserData();
    }

    if (!userDataEmpty()) {
        if (Date.now() < userData[SESSION_INFO.EXPIRY_DATE]) {
            return true;
        }

        // Expired!
        logout();
        return false;
    }

    return false;
}

export function logout() {
    localStorage.removeItem(SESSION_INFO.SESSION_INFO);
    userData = {};
}