<template>
  <v-app id="inspire">
    <v-app-bar app color="white" flat>
      <v-container class="py-0" fill-height fluid>
        <v-btn
            :disabled="!sideBarHidden"
            :ripple="false"
            class="mr-1"
            icon
            title
            @click.stop="toggleDrawer()"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>

        <h2>Ship Model Builder</h2>

        <v-spacer></v-spacer>

        <v-img max-height="64" max-width="120px" src="@/assets/logo.png">
        </v-img>
      </v-container>
    </v-app-bar>
    <app-drawer v-if="sideBarHidden" ref="drawer"/>

    <v-main class="indigo lighten-4">
      <v-container fluid>
        <v-row justify="center">
          <router-view/>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {isUserLoggedIn} from "@/utils/session";
import AppDrawer from "@/components/AppDrawer.vue";

export default {
  created() {
    document.title = "VPS Model Builder";
  },

  data: () => ({
    links: ["Dashboard", "Messages", "Profile", "Updates"],
  }),

  methods: {
    toggleDrawer() {
      this.$refs.drawer.enableDrawer();
    },
  },

  computed: {
    sideBarHidden() {
      return isUserLoggedIn();
    },
  },

  components: {
    "app-drawer": AppDrawer,
  },
};
</script>