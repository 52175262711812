import {ExponentialRegression, PowerRegression, SimpleLinearRegression,} from "ml-regression";

export function calculateTrendPoints(x, y, extraPoint = null) {
    const points = {
        exponential: [],
        power: [],
        linear: [],
    };

    const expRegression = new ExponentialRegression(x, y);

    const powerRegression = new PowerRegression(x, y);

    let logX = [];
    x.forEach((v) => logX.push(Math.log(v)));

    const linearRegression = new SimpleLinearRegression(logX, y);

    //console.log("Result", linearRegression, "Predict", linearRegression.predict(3));
    const minX = Math.min(...x);
    const maxX = Math.max(...x);
    const pointDistance = (maxX - minX) / 100;


    for (let i = minX; i < maxX; i += pointDistance) {
        predictPoint(points, i, x, y, expRegression, powerRegression, linearRegression)
    }

    if (extraPoint) {
        predictPoint(points, extraPoint, x, y, expRegression, powerRegression, linearRegression)
    }

    return points;
}

function predictPoint(points, i, x, y, expRegression, powerRegression, linearRegression) {
    const expPredicted = expRegression.predict(i);
    const powerPredicted = powerRegression.predict(i);
    const linearPredicted =
        linearRegression.slope * Math.log(i) + linearRegression.intercept;

    points.exponential.push({
        x: i,
        y: expPredicted,
    });
    points.power.push({
        x: i,
        y: powerPredicted,
    });
    points.linear.push({
        x: i,
        y: linearPredicted,
    });
}