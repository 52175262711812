<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card flat>
      <v-card-text>
        <v-row justify="center">
          <v-col>
            <v-container fluid>
              <v-row>
                <v-col class="pt-0 pb-0" cols="4">
                  <v-text-field
                      v-model="fields.loa"
                      :class="[getColorForValue('loa')]"
                      :rules="constraints.noNegativesReq.rules"
                      dense
                      label="LOA"
                      outlined
                      suffix="m"
                  ></v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="4">
                  <v-text-field
                      v-model="fields.lpp"
                      :class="[getColorForValue('lpp')]"
                      :rules="constraints.noNegativesReqCwp.rules"
                      dense
                      label="LPP"
                      outlined
                      suffix="m"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0" cols="4">
                  <v-text-field
                      v-model="fields.beam"
                      :class="[getColorForValue('beam')]"
                      :rules="constraints.noNegativesReqCwp.rules"
                      dense
                      label="Beam"
                      outlined
                      suffix="m"
                  ></v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="4">
                  <v-text-field
                      v-model="fields.depth"
                      :class="[getColorForValue('depth')]"
                      :rules="constraints.noNegativesReq.rules"
                      dense
                      label="Depth"
                      outlined
                      suffix="m"
                  ></v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="4">
                  <v-text-field
                      v-model="fields.lightweight"
                      :class="[getColorForValue('lightweight')]"
                      :rules="constraints.lightweight.rules"
                      dense
                      label="Lightweight"
                      outlined
                      suffix="t"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0" cols="4">
                  <v-text-field
                      v-model="fields.designDraft"
                      :class="[getColorForValue('designDraft')]"
                      :rules="constraints.noNegativesReqCwp.rules"
                      dense
                      label="Design Draft"
                      outlined
                      suffix="m"
                      @change="updateContractPointDraft"
                  >
                    <template v-slot:append>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        <p class="pa-0 mb-1">
                          Update contract point draft as required
                        </p>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="4">
                  <v-text-field
                      v-model="fields.designDeadweight"
                      :class="[getColorForValue('designDeadweight')]"
                      :rules="constraints.noNegativesReqCwp.rules"
                      dense
                      label="Design Deadweight"
                      outlined
                      suffix="t"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0" cols="4">
                  <v-text-field
                      v-model="fields.scantlingDraft"
                      :class="[getColorForValue('scantlingDraft')]"
                      :rules="constraints.noNegativesReqCwp.rules"
                      dense
                      label="Scantling Draft"
                      outlined
                      suffix="m"
                  ></v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="4">
                  <v-text-field
                      v-model="fields.scantlingDeadweight"
                      :disabled="shipModel && shipModel.vesselType != 'Container'"
                      :rules="constraints.noNegativesReqCwp.rules"
                      dense
                      label="Scantling Deadweight"
                      outlined
                      suffix="t"
                  ></v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="4">
                  <v-text-field
                      :error="!cwpValid()"
                      :label="waterPlaneText"
                      :value="calculateCwp.toFixed(4)"
                      dense
                      outlined
                      readonly
                  >
                    <template v-slot:append>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        <p class="pa-0 mb-1">
                          Acceptable ranges for Waterplane Coeff
                        </p>
                        <p class="pa-0 mb-1">Bulk: 0.86-0.96</p>
                        <p class="pa-0 mb-1">Container: 0.75-0.9</p>
                        <p class="pa-0 mb-1">Tanker: 0.8-0.95</p>
                        <p class="pa-0 mb-0">
                          Adjust Design Draft and Design Deadweight till Cwp
                          falls in range.
                        </p>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0">
                  <v-subheader>Superstructure Specifications</v-subheader>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0" cols="3">
                  <v-text-field
                      v-model="fields.hSuper"
                      :class="[getColorForValue('hSuper')]"
                      :rules="constraints.noNegativesReq.rules"
                      dense
                      label="Height super"
                      outlined
                      suffix="m"
                  ></v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="3">
                  <v-text-field
                      v-model="fields.bSuper"
                      :class="[getColorForValue('bSuper')]"
                      :rules="constraints.noNegativesReq.rules"
                      dense
                      label="Breadth super"
                      outlined
                      suffix="m"
                  ></v-text-field>
                </v-col>

                <v-spacer></v-spacer>
                <v-col class="pt-0 pb-0" cols="4">
                  <v-text-field
                      v-model="fields.grossTonnage"
                      :class="[getColorForValue('grossTonnage')]"
                      :rules="constraints.noNegativesReq.rules"
                      dense
                      label="Gross tonnage"
                      outlined
                      suffix="GT"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col class="ml-auto" cols="5">
            <v-simple-table dense fixed-header height="300px">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">Model Name</th>
                  <th class="text-right">Scantling Deadweight (t)</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in similarShips" :key="item.imoNumber">
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                              item.vesselName
                            }}</span>
                      </template>
                      <p class="pa-0 mb-1">
                        Vessel name: {{ item.vesselName }}
                      </p>
                      <p class="pa-0 mb-1">Shipyard: {{ item.shipyard }}</p>
                      <p class="pa-0 mb-1">
                        LPP: {{ item.lpp.toFixed(2) }} m
                      </p>
                      <p class="pa-0 mb-1">
                        Beam: {{ item.beam.toFixed(2) }} m
                      </p>
                      <p class="pa-0 mb-1">
                        Depth: {{ item.depth.toFixed(2) }} m
                      </p>
                      <p class="pa-0 mb-1">
                        ScantlingDraft: {{ item.scantlingDraft.toFixed(0) }} m
                      </p>
                      <p class="pa-0 mb-0">
                        ScantlingDeadweight:
                        {{ item.scantlingDeadweight.toFixed(0) }} t
                      </p>
                    </v-tooltip>
                  </td>
                  <td class="text-right">
                    {{ item.scantlingDeadweight.toFixed(2) }}
                  </td>
                  <td class="text-right">
                    <v-btn
                        class="pl-0 pr-0"
                        color="primary"
                        outlined
                        @click.stop="addSister(item)"
                    >
                      Add as sister
                    </v-btn>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
      <ihs-data-dialog
          ref="ihsDialog"
          :fieldNames="ihsFieldNames"
          :shipModel="shipModel"
          @dialogCompleted="dialogCompleted"
      />
      <add-sister-dialog ref="sisterDialog" :shipModel="shipModel"/>
    </v-card>
  </v-form>
</template>

<script>
import {getNumberRules} from "@/utils/inputRulesBuilder";
import {ModelBuilderMixin} from "@/utils/customMixins";
import IHSDataDialog from "@/components/IHSDataDialog";
import AddSisterDialog from "@/components/AddSisterDialog";

export default {
  name: "BuildSpecifications",
  mixins: [ModelBuilderMixin],

  data: () => ({
    refName: "buildSpecs",
    fields: {
      lpp: "",
      loa: "",
      beam: "",
      depth: "",
      hSuper: "",
      bSuper: "",
      lightweight: "",
      designDraft: "",
      scantlingDraft: "",
      designDeadweight: "",
      scantlingDeadweight: "",
      grossTonnage: 0,
    },

    ihsFieldNames: [
      ["lpp", "LPP (m)"],
      ["loa", "LOA (m)"],
      ["beam", "Beam (m)"],
      ["depth", "Depth (m)"],
      ["scantlingDraft", "Scantling Draft (t)"],
      ["lightweight", "Lightweight (t)"],
      ["designDraft", "Estimated design draft* (m)"],
      ["designDeadweight", "Estimated design deadweight* (t)"],
    ],

    shipModelLoaded: false,
  }),

  computed: {
    constraints() {
      return {
        noNegativesReqCwp: getNumberRules(this, {
          required: true,
          range: [0, -1],
        }),
        noNegativesReq: getNumberRules(this, {
          required: true,
          range: [0, -1],
        }),
        lightweight: getNumberRules(this, {
          required: true,
          range: [0, -1],
          greaterNotEqual: true,
        }),
      };
    },
    waterPlaneText() {
      let ok = this.cwpValid() ? "OK" : "Not OK";
      return "Cwp " + ok;
    },
    calculateCwp() {
      return (
          (this.fields.scantlingDeadweight - this.fields.designDeadweight) /
          1.025 /
          (this.fields.scantlingDraft - this.fields.designDraft) /
          this.fields.lpp /
          this.fields.beam
      );
    },
  },

  methods: {
    updateContractPointDraft() {
    },
    isValid() {
      return this.valid && this.cwpValid();
    },
    cwpValid() {
      const cwp = this.calculateCwp;

      if (this.shipModel && this.shipModel.vesselType) {
        if (this.shipModel.vesselType == "Bulk" && cwp > 0.86 && cwp < 0.96) {
          return true;
        } else if (
            this.shipModel.vesselType == "Container" &&
            cwp > 0.75 &&
            cwp < 0.9
        ) {
          return true;
        } else if (
            this.shipModel.vesselType == "Tanker" &&
            cwp > 0.8 &&
            cwp < 0.95
        ) {
          return true;
        }
      }

      return false;
    },
    enableIHSDataPicker(shipModel) {
      // Enable using ihs scantling deadweight for ihs for container ships
      if (shipModel.vesselType === "Container") {
        this.ihsFieldNames.push(["scantlingDeadweight", "Scantling deadweight (m)"]);
      }
      this.$refs.ihsDialog.enableDialog(shipModel, shipModel.ihsShipModel);
      this.fields.grossTonnage = shipModel.ihsShipModel.grossTonnage;
    },
    dialogCompleted(dialogFields) {
      for (const field in dialogFields) {
        this.fields[field] = dialogFields[field];
      }
    },
    setFields(data) {
      this.fields.lpp = data.lpp;
      this.fields.loa = data.loa;
      this.fields.beam = data.beam;
      this.fields.depth = data.depth;
      this.fields.hSuper = data.hSuper;
      this.fields.bSuper = data.bSuper;
      this.fields.lightweight = data.lightweight;
      this.fields.designDraft = data.designDraft;
      this.fields.scantlingDraft = data.scantlingDraft;
      this.fields.designDeadweight = data.designDeadweight;
      this.fields.scantlingDeadweight = data.scantlingDeadweight;
      this.fields.grossTonnage = data.grossTonnage;
    },
    addSister(sisterData) {
      this.$refs.sisterDialog.enableDialog(sisterData);
    },
  },

  props: {
    shipModel: Object,
    similarShips: Array,
  },

  watch: {
    shipModel(newShipModel) {
      if (newShipModel.lpp != null && !this.shipModelLoaded) {
        this.shipModelLoaded = true;
        this.fields.lpp = this.shipModel.lpp.toFixed(2);
        this.fields.loa = this.shipModel.loa.toFixed(2);
        this.fields.beam = this.shipModel.beam.toFixed(2);
        this.fields.depth = this.shipModel.depth.toFixed(2);
        this.fields.hSuper = this.shipModel.hSuper.toFixed(2);
        this.fields.bSuper = this.shipModel.bSuper.toFixed(2);
        this.fields.lightweight = this.shipModel.lightweight.toFixed(2);
        this.fields.designDraft = this.shipModel.designDraft.toFixed(2);
        this.fields.scantlingDraft = this.shipModel.scantlingDraft.toFixed(2);
        this.fields.designDeadweight = this.shipModel.designDeadweight.toFixed(
            2
        );
        this.fields.scantlingDeadweight = this.shipModel.scantlingDeadweight.toFixed(
            2
        );
      }
    },
    /*fields: {
      deep: true,
      handler() {
        this.cwpValid();
      },
    },*/
  },

  components: {
    "ihs-data-dialog": IHSDataDialog,
    "add-sister-dialog": AddSisterDialog,
  },
};
</script>

<style scoped>
.non-default-field >>> .v-text-field__slot input {
  color: green;
}

.non-default-ihs-field >>> .v-text-field__slot input {
  color: blue;
}
</style>